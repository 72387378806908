var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.$t('components.customersInvoices.invoices')))]),(
          _vm.$parent.item.stat == 1 &&
          _vm.$site.invoices_allow &&
          (_vm.$user.admin ||
            _vm.$user.role.invoices_add)
        )?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/invoices/create?customer_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.customersInvoices.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$parent.$t('components.customersInvoices.searchForAnInvoice'),"withoutCard":true,"emptyTableText":_vm.$parent.$t('components.customersInvoices.thereAreNoBills'),"filter":{customer_id: _vm.$route.params.id},"emptyTableSubText":_vm.$parent.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"statusSearch":true,"statusValues":[
        { title: _vm.$parent.$t('components.customersInvoices.situation'), value: '' },
        { title: _vm.$parent.$t('components.customersImportForm.all'), value: '' },
        { title: _vm.$parent.$t('components.customersInvoices.draft'), value: 0 },
        { title: _vm.$parent.$t('components.customersInvoices.sent'), value: 1 },
        { title: _vm.$parent.$t('components.customersInvoices.seen'), value: 2 },
        { title: _vm.$parent.$t('components.customersInvoices.driven'), value: 3 },
        { title: _vm.$parent.$t('components.customersInvoices.canceled'), value: 4 },
      ],"cloumns":[
        {
          column: 'code',
          title: _vm.$parent.$t('components.customersInvoices.invoice'),
          type: 'mainLink',
          sort: true,
        },
        {
          column: 'date',
          title: _vm.$parent.$t('components.customersInvoices.theDateOfTheInvoice'),
          type: 'text',
          sort: true,
        },
        {
          column: 'client_type',
          title: _vm.$parent.$t('components.customersInvoices.customerName'),
          type: 'clintLink',
          sort: false,
        },
        {
          column: 'total',
          title: _vm.$parent.$t('components.customersInvoices.cost'),
          type: 'text',
          sort: true,
        },
        {
          column: 'rest',
          title: _vm.$parent.$t('components.customersInvoices.balance'),
          type: 'text',
          sort: true,
        },
        {
          column: 'status',
          title: _vm.$parent.$t('components.customersInvoices.situation'),
          type: 'status',
          sort: true,
          values: [
            {
              title: _vm.$parent.$t('components.customersInvoices.draft'),
              value: 0,
              color: 'dark',
            },
            {
              title: _vm.$parent.$t('components.customersInvoices.sent'),
              value: 1,
              color: 'primary',
            },
            {
              title: _vm.$parent.$t('components.customersInvoices.seen'),
              value: 2,
              color: 'primary',
            },
            {
              title: _vm.$parent.$t('components.customersInvoices.driven'),
              value: 3,
              color: 'success',
            },
            {
              title: _vm.$parent.$t('components.customersInvoices.canceled'),
              value: 4,
              color: 'danger',
            },
          ],
        },
        {
          column: 'options',
          title: _vm.$parent.$t('components.customersInvoices.settings'),
          type: 'options',
          options: [
            { name: 'show' },
            {
              name: 'edit',
              role:
                _vm.$user.admin ||
                _vm.$user.role.invoices_edit,
            },
            { name: 'outLink', role: true },
            { name: 'printInvoice', role: true },
            { name: 'download', role: true },
            {
              name: 'delete',
              role:
                _vm.$user.admin ||
                _vm.$user.role.invoices_delete,
            },
          ],
        },
      ],"deleteText":{
        attention: _vm.$parent.$t('allerts.Attention'),
        areYouReallySureToDeleteTheItem:
          _vm.$parent.$t('allerts.areYouReallySureToDeleteTheItem'),
        close: _vm.$parent.$t('allerts.close'),
        confirm: _vm.$parent.$t('allerts.confirm'),
        loading: _vm.$parent.$t('allerts.loading'),
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }