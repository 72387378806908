var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.$t('components.customersPurchaseDiscounts.purchaseDiscounts')))]),(
          _vm.$site.salesPayments_allow &&
          (_vm.$user.admin ||
            _vm.$user.role.sales_payments_add)
        )?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/purchaseDiscounts/create?customer_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.customersPurchaseDiscounts.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$t('components.customersPurchaseDiscounts.FindACheck'),"withoutCard":true,"filter":{customer_id: _vm.$route.params.id},"emptyTableText":_vm.$parent.$t('components.customersPurchaseDiscounts.thereAreNosalesDiscounts'),"emptyTableSubText":_vm.$parent.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
        { column: 'code', title: _vm.$t('components.customersPurchaseDiscounts.Discount'), type: 'mainLink', sort: true },
        {
          column: 'client_type',
          title: _vm.$t('components.customersPurchaseDiscounts.customerName'),
          type: 'clintLink',
          sort: true,
        },
        {
          column: 'date',
          title: _vm.$parent.$t('components.customersPurchaseDiscounts.date'),
          type: 'text',
          sort: true,
        },
        {
          column: 'description',
          title: _vm.$parent.$t('components.customersPurchaseDiscounts.thatsAbout'),
          type: 'text',
          sort: true,
        },
        {
          column: 'cost',
          title: _vm.$parent.$t('components.customersPurchaseDiscounts.amount'),
          type: 'text',
          sort: true,
        },
        {
          column: 'options',
          title: _vm.$parent.$t('components.customersPurchaseDiscounts.settings'),
          type: 'options',
          options: [
            { name: 'show' },
            {
              name: 'edit',
              role:
                _vm.$user.admin ||
                _vm.$user.role.sales_payments_edit,
            },
            {
              name: 'delete',
              role:
                _vm.$user.admin ||
                _vm.$user.role.sales_payments_delete,
            },
          ],
        },
      ],"deleteText":{
        attention: _vm.$parent.$t('allerts.Attention'),
        areYouReallySureToDeleteTheItem:
          _vm.$parent.$t('allerts.areYouReallySureToDeleteTheItem'),
        close: _vm.$parent.$t('allerts.close'),
        confirm: _vm.$parent.$t('allerts.confirm'),
        loading: _vm.$parent.$t('allerts.loading'),
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }