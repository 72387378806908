import { render, staticRenderFns } from "./multiInvoices.vue?vue&type=template&id=763a72b6"
import script from "./multiInvoices.vue?vue&type=script&lang=js"
export * from "./multiInvoices.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports