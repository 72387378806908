var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('mainReport.lockers'),"description":_vm.$t('mainReport.Fromhereyoucancontrolyourlockersreports')}})],1),(
        (
          _vm.$user.role.available_reports.find(
            (el) => el.id == 9 || el.id == 10
          ) || _vm.$user.admin
        ) && _vm.$site.safes_allow
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.safes'),"icon":'fa-treasure-chest',"reports":[
          {
            name: _vm.$t('mainReport.TreasuryStatement'),
            link: './safesAccounts',
            role:
              _vm.$user.role.available_reports.find(
                (el) => el.id == 9
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.SafeBalances'),
            link: '/safersBalance',
            role:
              _vm.$user.role.available_reports.find(
                (el) => el.id == 10
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.safesMove'),
            link: '/safesMove',
            role:
              _vm.$user.role.available_reports.find(
                (el) => el.id == 10
              ) || _vm.$user.admin,
          },
        ]}}):_vm._e(),(
        (
          _vm.$user.role.available_reports.find(
            (el) => el.id == 25 || el.id == 26
          ) || _vm.$user.admin
        )&& 
        (_vm.$site.salesPayments_allow ||
            _vm.$site.purchasePayments_allow) &&
          (_vm.$user.admin ||
            _vm.$user.role.sales_payments_show ||
            _vm.$user.role.purchase_payments_show)
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.Payments'),"icon":'fa-credit-card-front',"reports":[
          {
            name: _vm.$t('mainReport.RecordSalesPayments'),
            link: './paymentHistory',
            role:
              _vm.$user.role.available_reports.find(
                (el) => el.id == 25
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.RecordPurchasePayments'),
            link: '/purchasePaymentHistory',
            role:
              _vm.$user.role.available_reports.find(
                (el) => el.id == 26
              ) || _vm.$user.admin,
          },
        ]}}):_vm._e(),(
        (
          _vm.$user.role.available_reports.find(
            (el) => el.id == 31 || el.id == 32
          ) || _vm.$user.admin
        )&& _vm.$site.expenses_allow
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.Expenses'),"icon":'fa-usd-square',"reports":[
          {
            name: _vm.$t('mainReport.ExpenseList'),
            link: './expenseHistory',
            role:
              _vm.$user.role.available_reports.find(
                (el) => el.id == 31
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.ExpensesByClassification'),
            link: '/expenseByCategory',
            role:
              _vm.$user.role.available_reports.find(
                (el) => el.id == 32
              ) || _vm.$user.admin,
          },
        ]}}):_vm._e(),(
        (
          _vm.$user.role.available_reports.find(
            (el) => el.id == 13 || el.id == 14
          ) || _vm.$user.admin
        )&&
        (_vm.$site.safesDeposits_allow ||
            _vm.$site.safesWithdrawals_allow ||
            _vm.$site.safesTransfers_allow) &&
          (_vm.$user.admin ||
            _vm.$user.role.safes_deposits_show ||
            _vm.$user.role.safes_withdrawals_show ||
            _vm.$user.role.safes_transfers_show)
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.vaultsOperations'),"icon":'fa-file-invoice-dollar',"reports":[
          {
            name: _vm.$t('mainReport.depositLockers'),
            link: './safeDepositHistory',
            role:
              _vm.$user.role.available_reports.find(
                (el) => el.id == 13
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.pullLockers'),
            link: '/safeWithdrawalHistory',
            role:
              _vm.$user.role.available_reports.find(
                (el) => el.id == 14
              ) || _vm.$user.admin,
          },
           {
            name: _vm.$t('mainReport.movingLockers'),
            link: '/safeTransferHistory',
            role:
              _vm.$user.role.available_reports.find(
                (el) => el.id == 14
              ) || _vm.$user.admin,
          },
        ]}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }