var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('users.users'),"description":_vm.$t('users.fromHereYouCanControlUsers')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('users.searchForAUser'),"emptyTableText":_vm.$t('users.thereAreNoUsers'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.users_add,"cloumns":[
                   {column: 'name',    title: _vm.$t('users.name') ,type: 'text' , sort: true },
                   {column: 'role_name',    title: _vm.$t('users.userType') ,type: 'text' },
                   {column: 'email',    title: _vm.$t('users.email') ,type: 'text' , sort: true },
                   {column: 'options', title: _vm.$t('users.settings'), type: 'options', options: [
                       {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.users_edit},
                       {name: 'delete', role: _vm.$user.admin || _vm.$user.role.users_delete},
                   ]}
                 ],"deleteText":{
                  attention: _vm.$t('allerts.Attention'),
                  areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                  close: _vm.$t('allerts.close'),
                  confirm: _vm.$t('allerts.confirm'),
                  loading: _vm.$t('allerts.loading'),
                 }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }