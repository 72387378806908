<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader 
          :title="$t('reports.totalReport.Reports')" 
          :description="$t('reports.totalReport.BalanceSheet')" 
        />
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{$t('reports.totalReport.ReportOptions')}}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>

        <div id="reportShow">
          <reportHeader 
            :title="$t('reports.totalReport.BalanceSheet')" 
            :details="[]"
          />
          
            <div class="card">
              <div class="row">
                <div class="col-md-6">
                  <div class="table-responsive">
                    <table
                      class="table table-sm table-nowrap card-table table-hover"
                    >
                      <thead>
                        <tr>
                          <th>
                            <strong>{{$t('reports.totalReport.debit')}}</strong>
                          </th>
                          <th>
                            <strong>{{$t('reports.totalReport.amount')}}</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="list" v-if="loading != 1">
                        <tr>
                          <td><strong>{{$t('balanceSheet.stores')}}</strong></td>
                          <td><router-link to="/ProductsValue">{{ totalStores }}</router-link></td>
                        </tr>

                        <tr>
                          <td><strong>{{$t('reports.totalReport.safesDebits')}}</strong></td>
                          <td><router-link to="/safersBalance">{{ totalSafesDebits }}</router-link></td>
                        </tr>
                        <tr>
                          <td><strong>{{$t('reports.totalReport.bankAccountsDebits')}}</strong></td>
                          <td><router-link to="/safersBalance">{{ totalBankAccountsDebits }}</router-link></td>
                        </tr>
                        <tr>
                          <td><strong>{{$t('reports.totalReport.customersDebits')}}</strong></td>
                          <td><router-link to="/customersBalance?status=0">{{ totalCustomersDebits }}</router-link></td>
                        </tr>
                        <tr>
                          <td><strong>{{$t('reports.totalReport.suppliersDebits')}}</strong></td>
                          <td><router-link to="/suppliersBalance?status=0">{{ totalSuppliersDebits }}</router-link></td>
                        </tr>
                        <tr>
                          <td><strong>{{$t('reports.totalReport.partnersDebits')}}</strong></td>
                          <td><router-link to="/partnersBalance?status=0">{{ totalPartnersDebits }}</router-link></td>
                        </tr>
                        <tr>
                          <td><strong>{{$t('reports.totalReport.customersChecks')}}</strong></td>
                          <td><router-link to="/chequesHistory?startDate=&stat=0">{{ totalCustomersSalesChecks }}</router-link></td>
                        </tr>
                        <tr>
                          <td><strong>{{$t('reports.totalReport.customersInstallments')}}</strong></td>
                          <td><router-link to="installmentsHistory?startDate=">{{ totalCustomersInstallments }}</router-link></td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>{{$t('balanceSheet.TotalAssets')}}</th>
                          <th>{{ totalDebit }}</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="table-responsive">
                    <table
                      class="table table-sm table-nowrap card-table table-hover"
                    >
                      <thead>
                        <tr>
                          <th>
                            <strong>{{$t('reports.totalReport.cridtor')}}</strong>
                          </th>
                          <th>
                            <strong>{{$t('reports.totalReport.amount')}}</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="list" v-if="loading != 1">
                        <tr>
                          <td><strong>{{$t('reports.totalReport.customersCridtors')}}</strong></td>
                          <td><router-link to="/customersBalance?status=1">{{ totalCustomersCridtors }}</router-link></td>
                        </tr>
                        <tr>
                          <td><strong>{{$t('reports.totalReport.suppliersCridtors')}}</strong></td>
                          <td><router-link to="/suppliersBalance?status=1">{{ totalSuppliersCridtors }}</router-link></td>
                        </tr>
                        <tr>
                          <td><strong>{{$t('reports.totalReport.partnersCridtors')}}</strong></td>
                          <td><router-link to="/partnersBalance?status=1">{{ totalPartnersCridtors }}</router-link></td>
                        </tr>
                        <tr>
                          <td><strong>{{$t('reports.totalReport.safesCridtors')}}</strong></td>
                          <td><router-link to="/safersBalance">{{ totalSafesCridtors }}</router-link></td>
                        </tr>
                        <tr>
                          <td><strong>{{$t('reports.totalReport.bankAccountsCridtors')}}</strong></td>
                          <td><router-link to="/safersBalance">{{ totalBankAccountsCridtors }}</router-link></td>
                        </tr>
                        <tr>
                          <td><strong>{{$t('reports.totalReport.SuppliersPurchaseChecks')}}</strong></td>
                          <td><router-link to="/purchaseChequesHistory?startDate=&stat=0">{{ totalSuppliersPurchaseChecks }}</router-link></td>
                        </tr>
                        <tr>
                          <td><strong>{{$t('reports.totalReport.SuppliersInstallments')}}</strong></td>
                          <td>
                            {{ totalSuppliersInstallments }}
                          </td>
                        </tr>
                        <tr>
                          <td><strong>{{$t('reports.totalReport.totalWorthyExpenses')}}</strong></td>
                          <td>
                            {{ totalWorthyExpenses }}
                          </td>
                        </tr>
                        <tr>
                          <td><strong>{{$t('balanceSheet.Profits')}} / {{$t('balanceSheet.CapitalLosses')}}</strong></td>
                          <td>{{profits}}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>{{$t('balanceSheet.TotalLiabilities')}}</th>
                          <th>{{totalDebit}}</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import axios from "axios";

import reportHeader from "@/elements/reports/reportHeader.vue";
import indexHeader from "@/elements/index/indexHeader.vue";
import dateFilter from "@/elements/reports/dateFilter.vue";
import searchInput from "@/elements/reports/searchInput.vue";
import actionButtons from "@/elements/reports/actionButtons.vue";
import Routelink from '@/includes/routelink.vue';

export default {
  data() {
    return {
      path: "/reports/totalReport",


      totalCustomers: 0,
      totalSafes: 0,
      totalBankAccounts: 0,
      totalCustomersSalesChecks: 0,
      totalCustomersInstallments: 0,
      totalSuppliers: 0,
      totalSuppliersPurchaseChecks: 0,
      totalSuppliersInstallments: 0,
      totalDebit: 0,
      totalWorthyExpenses: 0,
      profits: 0,

      loading: 0,

      params: {
        dateFilter: 2,
        startDate: "",
        endDate: "",
      },

      excel: {
        fileName: 'total report ',
        reportData: '[]'
      },

      users: [],

      searchStartDate: "",
      searchEndDate: "",
    };
  },
  methods: {
    getData() {
      this.loading = 1;

      this.totalCustomers = 0;
      
      axios
        .get(this.$linkGnirator(this.path), { params: this.params })
        .then((response) => {
          this.totalCustomersDebits          = this.$moneyFormat(response.data.totalCustomersDebits);
          this.totalSuppliersDebits          = this.$moneyFormat(response.data.totalSuppliersDebits);
          this.totalPartnersDebits           = this.$moneyFormat(response.data.totalPartnersDebits);
          this.totalStores                   = this.$moneyFormat(response.data.totalStores);
          this.totalSafesDebits              = this.$moneyFormat(response.data.totalSafesDebits);
          this.totalBankAccountsDebits       = this.$moneyFormat(response.data.totalBankAccounts);
          this.totalCustomersSalesChecks     = this.$moneyFormat(response.data.totalCustomersSalesChecks);
          this.totalCustomersInstallments    = this.$moneyFormat(response.data.totalCustomersInstallments);

          this.totalCustomersCridtors        = this.$moneyFormat(response.data.totalCustomersCridtors);
          this.totalSuppliersCridtors        = this.$moneyFormat(response.data.totalSuppliersCridtors);
          this.totalPartnersCridtors         = this.$moneyFormat(response.data.totalPartnersCridtors);
          this.totalSafesCridtors            = this.$moneyFormat(response.data.totalSafesCridtors);
          this.totalBankAccountsCridtors     = this.$moneyFormat(response.data.totalBankAccountsCridtors);
          this.totalSuppliersPurchaseChecks  = this.$moneyFormat(response.data.totalSuppliersPurchaseChecks);
          this.totalSuppliersInstallments    = this.$moneyFormat(response.data.totalSuppliersInstallments);
          this.totalWorthyExpenses           = this.$moneyFormat(response.data.totalWorthyExpenses);


          this.totalDebit = this.$moneyFormat(response.data.totalStores + response.data.totalSafesDebits + response.data.totalBankAccountsDebits + response.data.totalCustomersDebits + response.data.totalSuppliersDebits + response.data.totalPartnersDebits + response.data.totalCustomersSalesChecks + response.data.totalCustomersInstallments)
          this.profits = this.$moneyFormat(response.data.totalStores + response.data.totalSafesDebits + response.data.totalBankAccountsDebits + response.data.totalCustomersDebits + response.data.totalSuppliersDebits + response.data.totalPartnersDebits + response.data.totalCustomersSalesChecks + response.data.totalCustomersInstallments - response.data.totalCustomersCridtors - response.data.totalSuppliersCridtors - response.data.totalPartnersCridtors - response.data.totalSafesCridtors - response.data.totalBankAccountsCridtors - response.data.totalSuppliersPurchaseChecks - response.data.totalSuppliersInstallments - response.data.totalWorthyExpenses)

          this.searchStartDate = this.params.startDate;
          this.searchEndDate = this.params.endDate;

          this.loading = 0;
        });
    }
  },
  watch: {
    items : function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data  = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map(r => [...r.querySelectorAll('th, td')].map(td => td.textContent))
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData);
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams();
    if (typeof this.params.startDate === "undefined") {
      this.params.startDate = new Date().toISOString('en-US', { timeZone: 'Africa/Cairo' }).slice(0, 10);
    }
    this.getData();
  },
  components: {
    reportHeader,
    indexHeader,
    dateFilter,
    searchInput,
    actionButtons,
    Routelink,
  },
};
</script>
