var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.$t('components.bankAccountsPurchaseCheques.purchaseCheques')))]),(_vm.$parent.item.stat == 1 && _vm.$site.salesPayments_allow && (_vm.$user.admin || _vm.$user.role.sales_payments_add))?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/purchaseCheques/create?safe_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.bankAccountsPurchaseCheques.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$t('components.bankAccountsPurchaseCheques.FindACheck'),"withoutCard":true,"emptyTableText":_vm.$parent.$t('components.bankAccountsPurchaseCheques.thereAreNosalesCheques'),"filter":{safe_id: _vm.$route.params.id},"emptyTableSubText":_vm.$parent.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
                        {column: 'code',    title: _vm.$t('components.bankAccountsPurchaseCheques.batch') ,type: 'mainLink' , sort: true },
                        {column: 'client_type',    title: _vm.$t('purchaseCheques.supplierName') ,type: 'clintLink', sort: true},
                        {column: 'created_date',    title: _vm.$t('components.bankAccountsPurchaseCheques.RegistrationDate') ,type: 'text' , sort: true },
                        {column: 'date',    title: _vm.$parent.$t('components.bankAccountsPurchaseCheques.paymentDate') ,type: 'text' , sort: true },
                        {column: 'description',    title: _vm.$parent.$t('components.bankAccountsPurchaseCheques.thatsAbout') ,type: 'text' , sort: true },
                        {column: 'cost',    title: _vm.$parent.$t('components.bankAccountsPurchaseCheques.amount') ,type: 'text' , sort: true },
                        {column: 'payment_method_id',    title: _vm.$t('components.bankAccountsPurchaseCheques.PaymentMethod') ,type: 'link', to:'paymentMethod', sort: true, link: false},
                        {column: 'stat',    title: _vm.$t('components.bankAccountsPurchaseCheques.active') ,type: 'status', sort: true,  values: [
                          {title: _vm.$t('components.bankAccountsPurchaseCheques.Waiting'), value: 0, color: 'info'},
                          {title: _vm.$t('components.bankAccountsPurchaseCheques.driven'), value: 1, color: 'success'},
                        ]},
                        {column: 'options', title: _vm.$parent.$t('components.bankAccountsPurchaseCheques.settings'), type: 'options', options: [
                            {name: 'show'},
                            {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.sales_cheques_edit},
                            {name: 'pay',   role: _vm.$user.admin  || _vm.$user.role.sales_cheques_edit},
                            {name: 'delete', role: _vm.$user.admin || _vm.$user.role.sales_cheques_delete},
                        ]}
                      ],"deleteText":{
                       attention: _vm.$t('allerts.Attention'),
                       areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                       close: _vm.$t('allerts.close'),
                       confirm: _vm.$t('allerts.confirm'),
                       loading: _vm.$t('allerts.loading'),
                      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }