var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('mainReport.Purchases'),"description":_vm.$t('mainReport.Fromhereyoucancontrolyourpurchasereports')}})],1),_c('div',{staticClass:"col-md-6"},[(
        (
          _vm.$user.role.available_reports.find(
            (el) => el.id == 5 || el.id == 6 || el.id == 7 || el.id == 8
          ) || _vm.$user.admin
        ) 
        && 
        _vm.$site.suppliers_allow
      )?_c('reportsCard',{attrs:{"cardTitle":_vm.$t('mainReport.suppliers'),"icon":'fa-user-tie',"reports":[
        {
          name: _vm.$t('mainReport.TotalResourcAccount'),
          link: './suppliersTotalAccounts',
          role:
            _vm.$user.role.available_reports.find(
              (el) => el.id == 5
            ) || _vm.$user.admin,
        },
        {
          name: _vm.$t('mainReport.VendorAccountDetails'),
          link: '/suppliersDetailsAccounts',
          role:
            _vm.$user.role.available_reports.find(
              (el) => el.id == 6
            ) || _vm.$user.admin,
        },
        {
          name: _vm.$t('mainReport.suppliersBalances'),
          link: '/suppliersBalance',
          role:
            _vm.$user.role.available_reports.find(
              (el) => el.id == 7
            ) || _vm.$user.admin,
        },
        {
          name: _vm.$t('mainReport.SupplierGuide'),
          link: '/suppliersGuide',
          role:
            _vm.$user.role.available_reports.find(
              (el) => el.id == 8
            ) || _vm.$user.admin,
        },
        {
          name: _vm.$t('mainReport.inactiveSuppliers'),
          link: '/suppliersInactive',
          role:
            _vm.$user.role.available_reports.find(
              (el) => el.id == 4
            ) || _vm.$user.admin,
        },
        {
          name: _vm.$t('mainReport.supplierMovement'),
          link: '/supplierMovement',
          role:
            _vm.$user.role.available_reports.find(
              (el) => el.id == 4
            ) || _vm.$user.admin,
        },
        {
          name: _vm.$t('mainReport.purchasingCollecting'),
          link: '/purchasingCollecting',
          role:
            _vm.$user.role.available_reports.find(
              (el) => el.id == 4
            ) || _vm.$user.admin,
        },
      ]}}):_vm._e()],1),_c('div',{staticClass:"col-md-6"},[(
        (
          _vm.$user.role.available_reports.find(
            (el) => el.id == 15 || el.id == 16
          ) || _vm.$user.admin
        )&& _vm.$site.bills_allow
        )?_c('reportsCard',{attrs:{"cardTitle":_vm.$t('mainReport.purchases'),"icon":'fa-shopping-cart',"reports":[
          {
            name: _vm.$t('mainReport.PurchasesByInvoice'),
            link: './purchasesByBill',
            role:
              _vm.$user.role.available_reports.find(
                (el) => el.id == 15
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.PurchasesByProduct'),
            link: '/purchasesByItem',
            role:
              _vm.$user.role.available_reports.find(
                (el) => el.id == 16
              ) || _vm.$user.admin,
          },
        ]}}):_vm._e(),(
        (
          _vm.$user.role.available_reports.find(
            (el) => el.id == 23 || el.id == 24
          ) || _vm.$user.admin
        )&& _vm.$site.orders_allow
        )?_c('reportsCard',{attrs:{"cardTitle":_vm.$t('mainReport.Orders'),"icon":'fa-briefcase',"reports":[
          {
            name: _vm.$t('mainReport.OrdersByInvoice'),
            link: './purchasesOrdersByBill',
            role:
              _vm.$user.role.available_reports.find(
                (el) => el.id == 23
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.OrdersByProduct'),
            link: '/purchasesOrdersByItem',
            role:
              _vm.$user.role.available_reports.find(
                (el) => el.id == 24
              ) || _vm.$user.admin,
          },
        ]}}):_vm._e(),(
        (
          _vm.$user.role.available_reports.find(
            (el) => el.id == 19 || el.id == 20
          ) || _vm.$user.admin
        )&&_vm.$site.bills_allow
        )?_c('reportsCard',{attrs:{"cardTitle":_vm.$t('mainReport.bills'),"icon":'fa-exchange',"reports":[
          {
            name: _vm.$t('mainReport.ReturnedByInvoice'),
            link: './purchasesReturnsByBill',
            role:
              _vm.$user.role.available_reports.find(
                (el) => el.id == 19
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.ReturnedByProduct'),
            link: '/purchasesReturnsByItem',
            role:
              _vm.$user.role.available_reports.find(
                (el) => el.id == 20
              ) || _vm.$user.admin,
          },
        ]}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }