var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":'التقارير',"description":'المشتريات بالفاتورة.'}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"المورد","plaseholder":"اختر مورد","show":'name',"refrance":'id',"value":_vm.params.supplier_id,"values":_vm.$database.suppliers},model:{value:(_vm.params.supplier_id),callback:function ($$v) {_vm.$set(_vm.params, "supplier_id", $$v)},expression:"params.supplier_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"المستخدم","plaseholder":"اختر مستخدم","show":'name',"refrance":'id',"value":_vm.params.user_id,"values":this.users},model:{value:(_vm.params.user_id),callback:function ($$v) {_vm.$set(_vm.params, "user_id", $$v)},expression:"params.user_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"المشروع","plaseholder":"اختر مشروع","show":'name',"refrance":'id',"value":_vm.params.project_id,"values":_vm.$database.projects},model:{value:(_vm.params.project_id),callback:function ($$v) {_vm.$set(_vm.params, "project_id", $$v)},expression:"params.project_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"المخزن","plaseholder":"اختر مخزن","show":'name',"refrance":'id',"value":_vm.params.store_id,"values":_vm.$database.stores},model:{value:(_vm.params.store_id),callback:function ($$v) {_vm.$set(_vm.params, "store_id", $$v)},expression:"params.store_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"تقرير المشتريات بالفاتورة","details":[
            {title: 'اسم المورد', value : _vm.supplierName },
            {title: 'اسم المستخدم', value : _vm.userName },
            {title: 'المخزن', value : _vm.storeName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":'لايوجد بيانات حاليا',"emptyTableSubText":'جرب اضافة البعض واعد المحاولة',"cloumns":[
            { column: 'code', link: '/bills', title: 'الكود', type:'reportMainLink', sort: true},
            { column: 'date', title: 'التاريخ', type: 'text', sort: true },
            { column: 'client_type', title: 'اسم المورد', type: 'clintLink', sort: false },
            { column: 'user', title: 'المستخدم', type: 'text', sort: true },
            { column: 'subtotal', title: 'الأجمالي', type: 'text', sort: true },
            {
              column: 'edit',
              title: 'تعديل',
              type: 'text',
              sort: true,
            },
            {
              column: 'delivery',
              title: 'رسوم الشحن',
              type: 'text',
              sort: true,
            },
            {
              column: 'tax',
              title: 'الضرائب',
              type: 'text',
              sort: true,
            },
            {
              column: 'discount',
              title: 'خصم',
              type: 'text',
              sort: true,
            },
            {
              column: 'total',
              title: 'المجموع',
              type: 'text',
              sort: true,
            },
          ],"footer":[
            { colspan: 1, value: 'المجموع' },
            { colspan: 3, value: _vm.invoicesCount },
            { colspan: 1, value: _vm.subtotal },
            { colspan: 1, value: _vm.edit },
            { colspan: 1, value: _vm.delivery },
            { colspan: 1, value: _vm.tax },
            { colspan: 1, value: _vm.discount },
            { colspan: 1, value: _vm.total },
          ]}})],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" خيارات التقرير ")])])
}]

export { render, staticRenderFns }