var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t('components.stores.purchaseInvoices')))]),(_vm.$parent.item.stat == 1 && _vm.$site.bills_allow && (_vm.$user.admin || _vm.$user.role.bills_add))?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/bills/create?store_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.stores.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$t('bills.searchForABill'),"withoutCard":true,"emptyTableText":_vm.$t('bills.thereAreNoBills'),"filter":{store_id: _vm.$route.params.id},"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"statusSearch":true,"statusValues":[
                          {title: _vm.$t('quotations.situation'), value: ''},
                          {title: _vm.$t('quotations.all'), value: ''},
                          {title: _vm.$t('bills.open'), value: 0},
                          {title: _vm.$t('bills.driven'), value: 1},
                          {title: _vm.$t('bills.canceled'), value: 2},
                          ],"cloumns":[
                         {column: 'code',    title: _vm.$t('bills.bill') ,type: 'mainLink' , sort: true },
                         {column: 'date',    title: _vm.$t('bills.theDateOfTheInvoice') ,type: 'text' , sort: true },
                         {column: 'client_type',    title: _vm.$t('bills.supplierName') ,type: 'clintLink', sort: true},
                         {column: 'total',    title: _vm.$t('bills.cost') ,type: 'text' , sort: true },
                         {column: 'rest',    title: _vm.$t('bills.balance') ,type: 'text' , sort: true },
                         {column: 'status',    title: _vm.$t('bills.situation') ,type: 'status' , sort: true ,  values: [
                           {title: _vm.$t('bills.open'), value: 0, color: 'dark'},
                           {title: _vm.$t('bills.driven'), value: 1, color: 'success'},
                           {title: _vm.$t('bills.canceled'), value: 2, color: 'danger'},
                         ]},
                         {column: 'options', title: _vm.$t('bills.settings'), type: 'options', options: [
                             {name: 'show'},
                             {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.bills_edit},
                             {name: 'outLink',   role: true},
                             {name: 'printBill',   role: true},
                             {name: 'download',   role: true},
                             {name: 'delete', role: _vm.$user.admin || _vm.$user.role.bills_delete},
                         ]}
                       ],"deleteText":{
                          attention: _vm.$t('allerts.Attention'),
                          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                          close: _vm.$t('allerts.close'),
                          confirm: _vm.$t('allerts.confirm'),
                          loading: _vm.$t('allerts.loading'),
                         }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }