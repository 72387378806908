var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":'التقارير',"description":'سجل سحب الخزائن.'}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('dateFilter',{staticClass:"col-md-3"}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"المستخدم","plaseholder":"اختر مستخدم","show":'name',"refrance":'id',"value":_vm.params.user_id,"values":this.users},model:{value:(_vm.params.user_id),callback:function ($$v) {_vm.$set(_vm.params, "user_id", $$v)},expression:"params.user_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"الخزينة","plaseholder":"اختر خزينة","show":'name',"refrance":'id',"value":_vm.params.safe_id,"values":_vm.$database.safes},model:{value:(_vm.params.safe_id),callback:function ($$v) {_vm.$set(_vm.params, "safe_id", $$v)},expression:"params.safe_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"سجل إيداع الخزائن","details":[
            {title: 'اسم المستخدم', value : _vm.userName },
            {title: ' الخزينة', value : _vm.safeName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":'لايوجد بيانات حاليا',"emptyTableSubText":'جرب اضافة البعض واعد المحاولة',"cloumns":[
            { column: 'code', link: '/purchasePayments', title: 'الكود', type:'reportMainLink', sort: true},
            { column: 'date', title: 'التاريخ', type: 'text', sort: true },
            { column: 'user', title: 'المستخدم', type: 'text', sort: true },
            { column: 'safe_id', title: 'الخزينة', element: 'safe', type: 'link', to: 'safe', sort: true, link: true },
            { column: 'cost', title: 'المبلغ', type: 'text', sort: true },
          ],"footer":[
            { colspan: 4, value: 'المجموع' },
            { colspan: 1, value: _vm.cost },
          ]}})],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" خيارات التقرير ")])])
}]

export { render, staticRenderFns }