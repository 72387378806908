var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('invoices.invoices'),"description":_vm.$t('invoices.fromHereYouCanControlSalesInvoices')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('invoices.searchForAnInvoice'),"emptyTableText":_vm.$t('invoices.thereAreNoBills'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.invoices_add,"statusSearch":true,"statusValues":[
           { title: _vm.$t('orders.situation'), value: ''},
            { title: _vm.$t('orders.all'), value: ''},
            { title: _vm.$t('orders.NewOrder'), value: 1 },
            { title: _vm.$t('orders.BeingProcessed'), value: 2 },
            { title: _vm.$t('orders.readyForShipping'), value: 3 },
            { title: _vm.$t('orders.UnderShipping'), value: 4 },
            { title: _vm.$t('orders.shipped'), value: 5 },
            { title: _vm.$t('orders.complete'), value: 6 },
            { title: _vm.$t('orders.returnd'), value: 7 },
            { title: _vm.$t('orders.canceled'), value: 8 }
        ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('invoices.invoice'),
            type: 'mainLink',
            sort: true,
          },
          {
            column: 'date',
            title: _vm.$t('invoices.theDateOfTheInvoice'),
            type: 'text',
            sort: true,
          },
          {
            column: 'expiry_date',
            title: ' تاريخ الأستلام',
            type: 'text',
            sort: true,
          },
          {
            column: 'client_type',
            title: _vm.$t('invoices.customerName'),
            type: 'clintLink',
            sort: false,
          },
          {
            column: 'total',
            title: _vm.$t('invoices.cost'),
            type: 'text',
            sort: true,
          },
          {
            column: 'rest',
            title: _vm.$t('invoices.balance'),
            type: 'text',
            sort: true,
          },
          {column: 'status',    title: _vm.$t('orders.situation') ,type: 'status' , sort: true ,  values: [
            { title: _vm.$t('orders.NewOrder'), value: 1, color: 'dark'},
            { title: _vm.$t('orders.BeingProcessed'), value: 2, color: 'info' },
            { title: _vm.$t('orders.readyForShipping'), value: 3, color: 'info' },
            { title: _vm.$t('orders.UnderShipping'), value: 4, color: 'info' },
            { title: _vm.$t('orders.shipped'), value: 5, color: 'success' },
            { title: _vm.$t('orders.complete'), value: 6, color: 'success' },
            { title: _vm.$t('orders.returnd'), value: 7, color: 'danger'},
            { title: _vm.$t('orders.canceled'), value: 8, color: 'danger' },
          ]},
          {
            column: 'options',
            title: _vm.$t('invoices.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$user.admin || _vm.$user.role.invoices_edit,
              },
              {name: 'file'},
              {name: 'stat',   role: _vm.$user.admin || _vm.$user.role.orders_edit},
              {name: 'invoiceLink',   role: _vm.$user.admin || _vm.$user.role.sale_returns_add, title: _vm.$t('invoices.ReturnSale'), icon: 'exchange', link:'/saleReturns/create?invoice_id='},
              { name: 'outLink', role: true },
              { name: 'printInvoice', role: true },
              {name: 'printDelevary',   role: true},
              { name: 'download', role: true },
              {
                name: 'delete',
                role: _vm.$user.admin || _vm.$user.role.invoices_delete,
              },
            ],
          },
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem:
            _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading'),
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }