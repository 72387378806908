var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('reports.accounts.reports'),"description":_vm.$t('reports.overview.recordWithdrawalsAccountsOperations')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('reports.accounts.reportOptions'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('reports.accounts.account'),"plaseholder":_vm.$t('reports.accounts.chooseAccount'),"show":'name',"refrance":'id',"value":_vm.params.account_id,"values":_vm.$database.accounts},model:{value:(_vm.params.account_id),callback:function ($$v) {_vm.$set(_vm.params, "account_id", $$v)},expression:"params.account_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('reports.overview.theUser'),"plaseholder":_vm.$t('reports.overview.chooseUser'),"show":'name',"refrance":'id',"value":_vm.params.user_id,"values":this.users},model:{value:(_vm.params.user_id),callback:function ($$v) {_vm.$set(_vm.params, "user_id", $$v)},expression:"params.user_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('reports.overview.treasury'),"plaseholder":_vm.$t('reports.overview.ChooseSafe'),"show":'name',"refrance":'id',"value":_vm.params.safe_id,"values":_vm.$database.safes},model:{value:(_vm.params.safe_id),callback:function ($$v) {_vm.$set(_vm.params, "safe_id", $$v)},expression:"params.safe_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('reports.overview.recordWithdrawalsAccountsOperations'),"details":[
            {title: _vm.$t('reports.accounts.accuontName'), value : _vm.supplierName },
            {title: _vm.$t('reports.overview.userName'), value : _vm.userName },
            {title:_vm.$t('reports.overview.treasury'), value : _vm.safeName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('reports.accounts.thereNoDataTheMoment'),"emptyTableSubText":_vm.$t('reports.accounts.tryAddingSomeAndTryAgain'),"cloumns":[
            { column: 'code', link: '/accountsWithdrawals', title: _vm.$t('reports.accounts.code'), type:'reportMainLink', sort: true},
            { column: 'date', title: _vm.$t('reports.accounts.date'), type: 'text', sort: true },
            { column: 'partner', title: _vm.$t('reports.accounts.account'), type: 'text', sort: true },
            { column: 'user', title:_vm.$t('reports.overview.theUser'), type: 'text', sort: true },
            { column: 'safe', title: _vm.$t('reports.overview.treasury'), type: 'text', sort: true },
            { column: 'cost', title: _vm.$t('reports.overview.theAmount'), type: 'text', sort: true },
          ],"footer":[
            { colspan: 5, value:  _vm.$t('reports.overview.total') },
            { colspan: 1, value: _vm.cost },
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }