var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('addHeader',{attrs:{"title":_vm.$t('settings.settings'),"description":_vm.$t('settings.Fromhereyoucancontrolallsettings')}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('reportsCard',{attrs:{"cardTitle":_vm.$t('settings.Usersettings'),"icon":'fal fa-user-cog',"reports":[
              {
                name: _vm.$t('settings.profile'),
                link: './profile',
                role: _vm.$user.admin || _vm.$user.role.profile_edit,
              },
              {
                name: _vm.$t('settings.Companies'),
                link: './companies',
                role: _vm.$user.admin || _vm.$user.role.companies_show,
              },
              {
                name: _vm.$t('settings.userPermissions'),
                link: './roles',
                role: _vm.$user.admin || _vm.$user.role.roles_show,
              },
              {
                name: _vm.$t('settings.userAccounts'),
                link: './users',
                role: _vm.$user.admin || _vm.$user.role.users_show,
              },
            ]}}),_c('reportsCard',{attrs:{"cardTitle":_vm.$t('settings.Operationssettings'),"icon":'fal fa-cogs',"reports":[
              {
                name: _vm.$t('settings.numberingGroups'),
                link: './invoiceGroups',
                role:
                  _vm.$user.admin ||
                  _vm.$user.role.quotations_show ||
                  _vm.$user.role.invoices_show ||
                  _vm.$user.role.bills_show ||
                  _vm.$user.role.orders_show ||
                  _vm.$user.role.purchase_orders_show,
              },
              {
                name: _vm.$t('settings.invoiceStatus'),
                link: './invoiceStatus',
                role:
                  _vm.$user.admin ||
                  _vm.$user.role.quotations_show ||
                  _vm.$user.role.invoices_show ||
                  _vm.$user.role.bills_show ||
                  _vm.$user.role.orders_show ||
                  _vm.$user.role.purchase_orders_show,
              },
              {
                name: _vm.$t('settings.Signatures'),
                link: './Signatures',
                role: _vm.$user.admin || _vm.$user.signatures_show,
              },
            ]}}),_c('reportsCard',{attrs:{"cardTitle":_vm.$t('settings.productsettings'),"icon":'fal fa-boxes-alt',"reports":[
              {
                name: _vm.$t('settings.unitsProduct'),
                link: './units',
                role: _vm.$user.admin || _vm.$user.role.products_show,
              },
              {
                name: _vm.$t('settings.ProductDetails'),
                link: './details',
                role: _vm.$user.admin || _vm.$user.role.products_show,
              },
              {
                name: _vm.$t('settings.ProductsOptions'),
                link: './productOptions',
                role: _vm.$user.admin || _vm.$user.role.products_show,
              },
              {
                name: _vm.$t('settings.productCategories'),
                link: './sections',
                role: _vm.$user.admin || _vm.$user.role.products_show,
              },
              {
                name: _vm.$t('settings.unitsCoefficient'),
                link: '/unitsCoefficients',
                role: _vm.$user.admin || _vm.$user.role.products_show,
              },
            ]}})],1),_c('div',{staticClass:"col-md-6"},[_c('reportsCard',{attrs:{"cardTitle":_vm.$t('settings.Paymentsettings'),"icon":'fal fa-file-invoice-dollar',"reports":[
              {
                name: _vm.$t('settings.paymentMethods'),
                link: './paymentMethods',
                role:
                  _vm.$user.admin ||
                  _vm.$user.role.sales_payments_show ||
                  _vm.$user.role.purchase_payments_show ||
                  _vm.$user.role.expenses_show,
              },
              {
                name: _vm.$t('settings.paymentFees'),
                link: './paymentFees',
                role:
                  _vm.$user.admin ||
                  _vm.$user.role.sales_payments_show ||
                  _vm.$user.role.purchase_payments_show ||
                  _vm.$user.role.expenses_show,
              },
              {
                name: _vm.$t('settings.taxRates'),
                link: './taxes',
                role:
                  _vm.$user.admin ||
                  _vm.$user.role.quotations_show ||
                  _vm.$user.role.invoices_show ||
                  _vm.$user.role.bills_show ||
                  _vm.$user.role.orders_show ||
                  _vm.$user.role.purchase_orders_show ||
                  _vm.$user.role.products_show,
              },
              {
                name: _vm.$t('settings.discountRates'),
                link: './deliveries',
                role:
                  _vm.$user.admin ||
                  _vm.$user.role.quotations_show ||
                  _vm.$user.role.invoices_show ||
                  _vm.$user.role.bills_show ||
                  _vm.$user.role.orders_show ||
                  _vm.$user.role.purchase_orders_show ||
                  _vm.$user.role.products_show,
              },
              {
                name: _vm.$t('settings.freightForwarders'),
                link: './discounts',
                role: true,
              },
              {
                name: _vm.$t('settings.marketers'),
                link: './marketers',
                role: true,
              },
              {
                name: _vm.$t('settings.PriceLists'),
                link: './PriceLists',
                role: _vm.$user.admin || _vm.$user.role.products_show,
              },
              {
                name: _vm.$t('settings.sectionsBond'),
                link: './PaymentsSections',
                role: true,
              },
              {
                name: _vm.$t('settings.expenditureCategories'),
                link: './expenseSections',
                role: _vm.$user.admin || _vm.$user.role.expenses_show,
              },
              {
                name: _vm.$t('settings.sectionAccounts'),
                link: './sectionsAccounts',
                role: _vm.$user.admin || _vm.$user.role.products_show,
              },
            ]}}),_c('reportsCard',{attrs:{"cardTitle":_vm.$t('settings.Databases'),"icon":'fal fa-server',"reports":[
              {
                name: _vm.$t('settings.Applications'),
                link: './applications',
                role: true,
              },
            ]}}),_c('reportsCard',{attrs:{"cardTitle":_vm.$t('settings.Backup'),"icon":'fal fa-copy',"reports":[
              {
                name: _vm.$t('settings.Backup'),
                link: './Backup',
                role: _vm.$user.admin || _vm.$user.role.buckup_allow,
              },
            ]}})],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }