var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('invoiceGroups.groupsNumbering'),"description":_vm.$t('invoiceGroups.fromHereYouCanControlTheNumberingOfYourGroups')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('invoiceGroups.SearchForNumbering'),"emptyTableText":_vm.$t('invoiceGroups.thereIsNoNumbering'),"localData":true,"buttonRole":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
                   {column: 'name',    title:_vm.$t('invoiceGroups.theName') ,type: 'text' , sort: true },
                   {column: 'prefix',    title:_vm.$t('invoiceGroups.thePrefix') ,type: 'text' , sort: true },
                   {column: 'next_id',    title:_vm.$t('invoiceGroups.nextNumber') ,type: 'text' , sort: true },
                   {column: 'left_pad',    title:_vm.$t('invoiceGroups.leftMargin') ,type: 'text' , sort: true },
                   {column: 'prefix_year',    title:_vm.$t('invoiceGroups.yearPrefix') ,type: 'trueOrFalse' , sort: true},
                   {column: 'prefix_month',    title:_vm.$t('invoiceGroups.theMonthPrefix') ,type: 'trueOrFalse' , sort: true},
                   {column: 'options', title:_vm.$t('invoiceGroups.settings'), type: 'options', options: [
                       {name: 'edit',   role: true},
                       {name: 'delete', role: true},
                   ]}
                 ],"deleteText":{
                  attention: _vm.$t('allerts.Attention'),
                  areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                  close: _vm.$t('allerts.close'),
                  confirm: _vm.$t('allerts.confirm'),
                  loading: _vm.$t('allerts.loading'),
                 }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }