var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('storesTransfers.storesTransfersOperations'),"description":_vm.$t('storesTransfers.fromHereYouCanControlStoresTransfersOperations')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('storesTransfers.searchForAnStoresTransfersOperations'),"emptyTableText":_vm.$t('storesTransfers.thereAreNostoresTransfersOperations'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.stores_transfers_add,"statusSearch":true,"statusValues":[
                      { title: _vm.$t('orders.situation'), value: ''},
                      { title: _vm.$t('orders.all'), value: ''},
                      { title: _vm.$t('invoices.draft'), value: 0 },
                      { title: _vm.$t('invoices.approved'), value: 1 },
                  ],"cloumns":[
                                {column: 'code',    title: _vm.$t('storesTransfers.operation') ,type: 'mainLink' , sort: true },
                                {column: 'from_store_id',    title: _vm.$t('storesTransfers.fromStoreName'), element:'fromStore', type: 'link', to:'store', sort: true, link: true},
                                {column: 'to_store_id',    title: _vm.$t('storesTransfers.toStoreName'), element:'toStore', type: 'link', to:'store', sort: true, link: true},
                                {column: 'date',    title: _vm.$t('storesTransfers.theDateOfTheOperation') ,type: 'text' , sort: true },
                                {column: 'description',    title: _vm.$t('storesTransfers.description') ,type: 'text' , sort: true },
                                {column: 'status', title: _vm.$t('bills.situation') ,type: 'status' , sort: true ,  values: [
                                      {title: _vm.$t('invoices.draft'), value: 0, color: 'dark'},
                                      {title: _vm.$t('invoices.approved'), value: 1, color: 'success'},
                                    ]
                                },
                                {column: 'options', title: _vm.$t('storesTransfers.settings'), type: 'options', options: [
                                    {name: 'show'},
                                    {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.stores_transfers_edit},
                                    {name: 'file'},
                                    {name: 'printStoresTransfer',   role: true},
                                    {name: 'delete', role: _vm.$user.admin || _vm.$user.role.stores_transfers_delete},
                                ]}
                              ],"deleteText":{
                               attention: _vm.$t('allerts.Attention'),
                               areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                               close: _vm.$t('allerts.close'),
                               confirm: _vm.$t('allerts.confirm'),
                               loading: _vm.$t('allerts.loading'),
                              }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }