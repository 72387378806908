var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.$t('manufacturingOperations.Deposits')))]),_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/manufacturingDeposits/create?manufacturing_operation_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('manufacturingOperations.addition'))+" ")])],1),_c('indexTable',{attrs:{"searchText":_vm.$t('manufacturingDeposits.SearchForAnOperation'),"emptyTableText":_vm.$t('manufacturingDeposits.ThereAreNoWithdrawalsWithManufacturingProcesses'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"filter":{manufacturing_operation_id: _vm.$route.params.id},"withoutCard":true,"cloumns":[
          {
            column: 'code',
            title: _vm.$t('manufacturingDeposits.operation'),
            type: 'mainLink',
            sort: true,
          },
          {column: 'manufacturing_operation_id',    title: _vm.$t('manufacturingDeposits.TheCodeOfTheOperation') ,type: 'linkInvoice', to:'manufacturingOperation', sort: true, link: true},
          {
            column: 'store_id',
            title: _vm.$t('manufacturingDeposits.TheNameOfTheStore'),
            type: 'link',
            to: 'store',
            sort: true,
            link: true,
          },
          {
            column: 'date',
            title: _vm.$t('manufacturingDeposits.theDateOfTheOperation'),
            type: 'text',
            sort: true,
          },
          {
            column: 'cost',
            title: _vm.$t('manufacturingDeposits.cost'),
            type: 'text',
            sort: true
          },
          {
            column: 'description',
            title: _vm.$t('manufacturingDeposits.thatAbout'),
            type: 'text',
            sort: true,
          },
          {
            column: 'options',
            title: _vm.$t('manufacturingDeposits.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role:
                  _vm.$user.admin ||
                  _vm.$user.role.manufacturing_deposits_edit,
              },
              { name: 'printmanufacturingDeposit', role: true },
              {
                name: 'delete',
                role:
                  _vm.$user.admin ||
                  _vm.$user.role.manufacturing_deposits_delete,
              },
            ],
          },
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem:
            _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading'),
        }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }