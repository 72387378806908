var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"exampleInputEmail1"}},[_vm._v(_vm._s(_vm.$t('reports.overview.duration')))]),_c('VueCtkDateTimePicker',{attrs:{"label":_vm.$t('reports.overview.ChooseTimeAndDate'),"button-now-translation":_vm.$t('reports.overview.now'),"format":"YYYY-MM-DD","formated":"YYYY-MM-DD","color":"#631263","button-color":"#631263","locale":"ar","range":true,"onlyDate":true,"custom-shortcuts":[
          { key: 'thisWeek', label: _vm.$t('reports.overview.thisWeek'), value: 'isoWeek' },
          { key: 'lastWeek', label: _vm.$t('reports.overview.theLastWeek'), value: '-isoWeek' },
          { key: 'last7Days', label: _vm.$t('reports.overview.theLastDays'), value: 7 },
          { key: 'last30Days', label: _vm.$t('reports.overview.theLast30Days'), value: 30 },
          { key: 'thisMonth', label: _vm.$t('reports.overview.thisMonth'), value: 'month' },
          { key: 'lastMonth', label: _vm.$t('reports.overview.previousMonth'), value: '-month' },
          { key: 'thisYear', label:_vm.$t('reports.overview.thisYear'), value: 'year' },
          { key: 'lastYear', label: _vm.$t('reports.overview.lastYear'), value: '-year' },
        ]},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }