var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"icon":'store-alt',"title":_vm.item.name,"subData":[
          {title: _vm.$t('pickupLocations.form.theState'), value: _vm.item.country},
          {title: _vm.$t('pickupLocations.form.city'), value: _vm.item.city},
        ],"buttons":[
          {title: _vm.$t('pickupLocations.show.modulation'), icon: 'edit', link: _vm.path+'/'+_vm.$route.params.id+'/edit', role: _vm.$user.admin || _vm.$user.role.pickup_locations_edit}
        ]}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('pickupLocations.show.theAddressOfThePickupLocations'),"cloumns":{
          firstCloum: [
            {icon: 'map-marker-alt', title: _vm.$t('pickupLocations.show.title'), value: _vm.item.address1},
            {icon: 'location-arrow', title: _vm.$t('pickupLocations.show.theEntity'), value: _vm.item.entity},
            {icon: 'map-marked', title: _vm.$t('pickupLocations.show.postalCode'), value: _vm.item.zip},
          ],
          scondCloum: [
            {icon: 'map-marker-alt', title: _vm.$t('pickupLocations.show.supplement'), value: _vm.item.address2},
            {icon: 'city', title: _vm.$t('pickupLocations.show.city'), value: _vm.item.city},
            {icon: 'globe-americas', title: _vm.$t('pickupLocations.show.theState'), value: _vm.item.country},
          ]
        }}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('pickupLocations.show.contactInformation'),"cloumns":{
          firstCloum: [
            {icon: 'phone', title: _vm.$t('pickupLocations.show.mobileNumber'), value: _vm.item.mobile}
          ],
          scondCloum: [
            {icon: 'phone', title: _vm.$t('pickupLocations.show.anotherNumber'), value: _vm.item.mobile2}
          ]
        }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }