var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('customers.index.customers'),"description":_vm.$t('customers.index.customersDescription'),"showExport":'true',"exportLink":_vm.path + '/export/excel',"showImport":'true',"explanationPath":_vm.path + '/explaination'}}),_c('indexTable',{attrs:{"searchText":_vm.$t('customers.index.searchForCustomer'),"emptyTableText":_vm.$t('customers.index.thereAreNoCustomers'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.admin || _vm.$user.role.customers_add,"localData":true,"cloumns":[
                    {column: 'name',    title:_vm.$t('customers.index.customerName') ,type: 'mainLink' , sort: true },
                    {column: 'mobile',  title:_vm.$t('customers.index.phoneNumber')  ,type: 'text'     , sort: true, icon: 'phone' },
                    {column: 'balance', title:_vm.$t('customers.index.balance')      ,type: 'text'     , sort: true, icon: 'usd-square' },
                    {column: 'stat',    title:_vm.$t('customers.index.active')      ,type: 'stat'     , sort: true, hideMobile: true },
                    {column: 'options', title:_vm.$t('customers.index.actions'), type: 'options', options: [
                        {name: 'show'},
                        {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.customers_edit},
                        {name: 'link',   role: _vm.$site.salesPayments_allow && (_vm.$user.admin|| _vm.$user.role.sales_payments_add), title: _vm.$t('customers.index.AddABatch'), icon: 'dollar-sign', link:'/salesPayments/create?customer_id='},
                        {name: 'link',   role: _vm.$site.quotations_allow && (_vm.$user.admin || _vm.$user.role.quotations_add), title: _vm.$t('customers.index.AddABid'), icon: 'calculator', link:'/quotations/create?customer_id='},
                        {name: 'link',   role: _vm.$site.invoices_allow && (_vm.$user.admin || _vm.$user.role.invoices_add), title: _vm.$t('customers.index.AddASalesBill'), icon: 'file-invoice-dollar', link:'/invoices/create?customer_id='},
                        {name: 'link',   role: _vm.$site.orders_allow && (_vm.$user.admin || _vm.$user.role.orders_add), title:_vm.$t('customers.index.AddARequest'), icon: 'briefcase', link:'/orders/create?customer_id='},
                        {name: 'delete', role: _vm.$user.admin || _vm.$user.role.customers_delete},
                    ]}
                  ],"deleteText":{
                   attention: _vm.$t('allerts.Attention'),
                   areYouReallySureToDeleteTheItem: _vm.$t('allerts.AreYouReallySureTheClientIsDeletedIfYouDeleteTheCustomerAllInvoicesAndProcessesWillBeDeleted'),
                   close: _vm.$t('allerts.close'),
                   confirm: _vm.$t('allerts.confirm'),
                   loading: _vm.$t('allerts.loading'),
                  }}})],1)]),(_vm.importShow)?_c('importForm'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }