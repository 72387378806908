var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid main-show"},[(!_vm.loaded)?_c('mainLoader'):_vm._e(),(_vm.loaded)?_c('div',{staticClass:"row mt-3"},[(
        _vm.$site.reports_allow &&
        ((_vm.$option.main_elements.find((el) => el.id == 1) && _vm.$user.admin) ||
          _vm.$user.role.main_elements.find((el) => el.id == 1))
      )?_c('billsAndPayment',{staticClass:"col-12 col-lg-6",attrs:{"items":_vm.data.billsAndPayment,"lastYierItems":_vm.data.billsAndPaymentLastYier}}):_vm._e(),(
        (_vm.$option.main_elements.find((el) => el.id == 8) && _vm.$user.admin) ||
        _vm.$user.role.main_elements.find((el) => el.id == 8)
      )?_c('urgentActions',{staticClass:"col-12 col-lg-6"}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }