var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('PaymentsSections.PaymentClassifications'),"description":_vm.$t('PaymentsSections.FromHereYouCanControlTheClassificationsOfPayments')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('PaymentsSections.SearchForARating'),"emptyTableText":_vm.$t('PaymentsSections.ThereAreNoRatings'),"emptyTableSubText":_vm.$t('PaymentsSections.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.payments_add,"cloumns":[
                 {column: 'name',    title:_vm.$t('PaymentsSections.sectionName') ,type: 'text' , sort: true },
                 {column: 'payment_section_id',    title:_vm.$t('sections.fatherSection') ,type: 'link', to:'paymentSection', sort: true, link: false },
                 {column: 'options', title:_vm.$t('PaymentsSections.Settings'), type: 'options', options: [
                 {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.expenses_edit},
                 {name: 'delete', role: _vm.$user.admin || _vm.$user.role.expenses_delete},
                 ]}
               ],"deleteText":{
                attention: _vm.$t('allerts.Attention'),
                areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                close: _vm.$t('allerts.close'),
                confirm: _vm.$t('allerts.confirm'),
                loading: _vm.$t('allerts.loading'),
               }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }