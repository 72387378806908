var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex mt-3"},[_c('div',{staticClass:"ml-auto"},[_c('h2',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t('pointOfSales.expenses.pointOfSalesExpenses'))+" ")]),_c('p',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t('pointOfSales.expenses.fromHereYouCanControlPOSExpenses'))+" ")])]),_c('router-link',{staticClass:"btn btn-primary add-btn",attrs:{"to":'/pointofsales/' + this.$route.params.id}},[_c('i',{staticClass:"fas fa-cash-register mr-2"}),_vm._v(" "+_vm._s(_vm.$t('pointOfSales.invoices.PointOfSaleOffer'))+" ")])],1),_c('indexTable',{attrs:{"searchText":_vm.$t('expenses.SearchForAllowance'),"emptyTableText":_vm.$t('expenses.thereAreNoExpenses'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"filter":{point_of_sale_id: _vm.$route.params.id, count: 2000},"buttonRole":_vm.$user.role.expenses_add,"deleteRoute":"/expenses","localDatabaseTable":"expenses","addPopUp":true,"table":"expenses","cloumns":[
                     {column: 'code',               title: _vm.$t('expenses.PerDiem'),type: 'text',sort: true},
                     {column: 'date',               title: _vm.$t('expenses.expenseDate') ,type: 'text' , sort: true },
                     {column: 'expense_section_id', title: _vm.$t('expenses.section') ,type: 'link', to:'expenseSection', sort: true, link: false},
                     {column: 'description',        title: _vm.$t('expenses.thatsAbout') ,type: 'text' , sort: true },
                     {column: 'cost',               title: _vm.$t('expenses.amount') ,type: 'text' , sort: true },

                     {column: 'options', title: _vm.$t('expenses.settings'), type: 'options', options: [
                         {name: 'show'},
                         {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.expenses_edit},
                         {name: 'delete', role: _vm.$user.admin || _vm.$user.role.expenses_delete},
                     ]}
                   ],"deleteText":{
                    attention: _vm.$t('allerts.Attention'),
                    areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                    close: _vm.$t('allerts.close'),
                    confirm: _vm.$t('allerts.confirm'),
                    loading: _vm.$t('allerts.loading'),
                   }}})],1)]),(_vm.showAddPopUp)?_c('expenseForm'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }