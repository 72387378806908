var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('pickupLocations.ReceivingPoints'),"description":_vm.$t('pickupLocations.fromHereYouCanControlYourReceiptPoints'),"explanationPath":_vm.path + '/explaination'}}),_c('indexTable',{attrs:{"searchText":_vm.$t('pickupLocations.SearchForAReceivingPoint'),"emptyTableText":_vm.$t('pickupLocations.ThereAreNoReceivingPoints'),"buttonRole":_vm.$user.role.pickupLocations_add,"localData":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
                   {column: 'name',    title:_vm.$t('pickupLocations.theNameOfThePickupLocations') ,type: 'mainLink' , sort: true },
                   {column: 'mobile',    title:_vm.$t('pickupLocations.mobile')     ,type: 'text'     , sort: true },
                   {column: 'city',    title:_vm.$t('pickupLocations.City')     ,type: 'text'     , sort: true },
                   {column: 'country', title:_vm.$t('pickupLocations.Country')       ,type: 'text'     , sort: true },
                   {column: 'options', title:_vm.$t('pickupLocations.settings'), type: 'options', options: [
                       {name: 'show'},
                       {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.pickupLocations_edit},
                       {name: 'link',   role: _vm.$site.pickupLocationsTransfers_allow && (_vm.$user.admin|| _vm.$user.role.pickupLocations_transfers_add), title: _vm.$t('pickupLocations.MoveFromTheStore'), icon: 'suitcase', link:'/pickupLocationsTransfers/create/'},
                       {name: 'link',   role: _vm.$site.pickupLocationsDeposits_allow && (_vm.$user.admin|| _vm.$user.role.pickupLocations_deposits_add), title: _vm.$t('pickupLocations.DepositToTheStore'), icon: 'donate', link:'/pickupLocationsDeposits/create/'},
                       {name: 'link',   role: _vm.$site.pickupLocationsWithdrawals_allow && (_vm.$user.admin|| _vm.$user.role.pickupLocations_withdrawals_add), title: _vm.$t('pickupLocations.EnglishPullOutOfTheStore'), icon: 'money-bill-alt', link:'/pickupLocationsWithdrawals/create/'},
                       {name: 'delete', role: _vm.$user.admin || _vm.$user.role.pickupLocations_delete},
                   ]}

                 ],"deleteText":{
                  attention: _vm.$t('allerts.Attention'),
                  areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                  close: _vm.$t('allerts.close'),
                  confirm: _vm.$t('allerts.confirm'),
                  loading: _vm.$t('allerts.loading'),
                 }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }