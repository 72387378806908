import { render, staticRenderFns } from "./reportHeader.vue?vue&type=template&id=7224b757"
import script from "./reportHeader.vue?vue&type=script&lang=js"
export * from "./reportHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports