var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t('components.materials.storeOperations'))+" ")])]),_c('indexTable',{attrs:{"emptyTableText":_vm.$t('components.materials.thereAreNoProducts'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"filter":{product_id: _vm.$route.params.id},"withoutCard":true,"cloumns":[
                           {column: 'store_id', title:_vm.$t('stores.storeName')     ,type: 'link', to:'store', sort: true, link: true},
                           {column: 'quantity',    title:_vm.$t('components.materials.quantity')       ,type: 'text'},
                           {column: 'opreation',    title:_vm.$t('components.materials.reference')   ,type: 'opreation'},
                           {column: 'date',    title:_vm.$t('quotations.form.date')       ,type: 'text'},
                           {column: 'type',    title:_vm.$t('components.materials.operationType')  ,type: 'storeStat'},
                         ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }