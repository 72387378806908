var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.$t('components.bankAccountsSafesDeposits.DepositsToTheBankAccount')))]),(_vm.$parent.item.stat == 1 && _vm.$site.salesPayments_allow && (_vm.$user.admin || _vm.$user.role.sales_payments_add))?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/safesDeposits/create?safe_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.bankAccountsSafesDeposits.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$t('components.bankAccountsSafesDeposits.SearchForAProcess'),"withoutCard":true,"emptyTableText":_vm.$t('components.bankAccountsSafesDeposits.ThereAreCurrentlyNoDeposits'),"filter":{safe_id: _vm.$route.params.id},"emptyTableSubText":_vm.$t('components.bankAccountsSafesDeposits.TryAddingSomeAndTryAgain'),"cloumns":[
                     {column: 'code',       title: _vm.$t('components.bankAccountsSafesDeposits.operation') ,      type: 'mainLink' , sort: true },
                     {column: 'safe_id',    title:_vm.$t('components.bankAccountsSafesDeposits.Treasury'),      type: 'link', to:'safe', sort: true, link: true},
                     {column: 'date',       title: _vm.$t('components.bankAccountsSafesDeposits.date') ,    type: 'text' , sort: true },
                     {column: 'description',title: _vm.$t('components.bankAccountsSafesDeposits.thatAbout') ,   type: 'text' , sort: true },
                     {column: 'cost',       title: _vm.$t('components.bankAccountsSafesDeposits.theAmount') ,     type: 'text' , sort: true },
                     {column: 'options',    title: _vm.$t('components.bankAccountsSafesDeposits.settings'),    type: 'options', options: [
                         {name: 'show'},
                         {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.safes_deposits_edit},
                         {name: 'delete', role: _vm.$user.admin || _vm.$user.role.safes_deposits_delete},
                     ]}
                   ],"deleteText":{
                    attention: _vm.$parent.$t('allerts.Attention'),
                    areYouReallySureToDeleteTheItem: _vm.$parent.$t('allerts.areYouReallySureToDeleteTheItem'),
                    close: _vm.$parent.$t('allerts.close'),
                    confirm: _vm.$parent.$t('allerts.confirm'),
                    loading: _vm.$parent.$t('allerts.loading'),
                   }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }