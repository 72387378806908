var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('marketers.marketers'),"description":_vm.$t('marketers.FromHereYouCanControlTheMarketers')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('marketers.SearchForAMarketer'),"emptyTableText":_vm.$t('marketers.ThereAreNoMarketers'),"buttonRole":true,"localData":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
                   {column: 'name',    title:_vm.$t('marketers.marketerName') ,type: 'text' , sort: true },
                   {column: 'balance', title:_vm.$t('marketers.balance')      ,type: 'text'     , sort: true },
                   {column: 'options', title:_vm.$t('marketers.settings'), type: 'options', options: [
                       {name: 'edit',   role: true},
                       {name: 'delete', role: true},
                   ]}
                 ],"deleteText":{
                  attention: _vm.$t('allerts.Attention'),
                  areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                  close: _vm.$t('allerts.close'),
                  confirm: _vm.$t('allerts.confirm'),
                  loading: _vm.$t('allerts.loading'),
                 }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }