var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-sm table-nowrap card-table table-hover pos-table pos-big-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$t('components.pointOfSalesShow.productName'))+" ")]),(
                  _vm.$parent.options.show_cost == 2 ||
                  (_vm.$parent.options.show_cost == 1 && _vm.$parent.showCost)
                )?_c('th',[_vm._v(" "+_vm._s(_vm.$t('components.pointOfSalesShow.cost'))+" ")]):_vm._e(),_c('th',{class:_vm.$parent.options.change_price ? 'quantity-input' : ''},[_vm._v(" "+_vm._s(_vm.$t('components.pointOfSalesShow.price'))+" ")]),_c('th',{staticClass:"quantity-input"},[_vm._v(" "+_vm._s(_vm.$t('pointOfSales.show.Lots'))+" ")]),(_vm.$parent.options.change_product_discount)?_c('th',{staticClass:"quantity-input"},[_vm._v(" "+_vm._s(_vm.$t('components.pointOfSalesShow.Discount'))+" ")]):_vm._e(),_c('th',[_vm._v(" "+_vm._s(_vm.$t('pointOfSales.show.Total'))+" ")]),(_vm.$parent.item.invoiceItems.length > 0)?_c('th'):_vm._e()])]),_c('tbody',{staticClass:"list"},[(_vm.$parent.item.invoiceItems.length == 0)?_c('td',{staticClass:"empty-table",attrs:{"colspan":"10"}},[_c('i',{staticClass:"far fa-scanner d-block"}),_vm._v(" "+_vm._s(_vm.$t('pointOfSales.show.ThereAreNoProducts'))+" ")]):_vm._e(),_vm._l((_vm.$parent.item.invoiceItems),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.detailName ? "(" + item.detailName + ")" : "")+" ")]),(
                  _vm.$parent.options.show_cost == 2 ||
                  (_vm.$parent.options.show_cost == 1 && _vm.showCost)
                )?_c('td',[_vm._v(" "+_vm._s(item.cost)+" "+_vm._s(_vm.$option.currency)+" ")]):_vm._e(),_c('td',{class:_vm.$parent.options.change_price ? 'quantity-input' : ''},[(_vm.$parent.options.change_price)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.price),expression:"item.price"}],staticClass:"form-control",attrs:{"type":"number","min":"1"},domProps:{"value":(item.price)},on:{"keyup":function($event){return _vm.$parent.calculateTotal()},"input":function($event){if($event.target.composing)return;_vm.$set(item, "price", $event.target.value)}}}):_vm._e(),_vm._v(" "+_vm._s(!_vm.$parent.options.change_price ? item.price + " " + _vm.$option.currency : "")+" ")]),_c('td',{staticClass:"quantity-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.quantity),expression:"item.quantity"}],staticClass:"form-control",attrs:{"type":"number","min":"1"},domProps:{"value":(item.quantity)},on:{"keyup":function($event){return _vm.$parent.calculateTotal()},"input":function($event){if($event.target.composing)return;_vm.$set(item, "quantity", $event.target.value)}}})]),(_vm.$parent.options.change_product_discount)?_c('td',{staticClass:"quantity-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.discount),expression:"item.discount"}],staticClass:"form-control",attrs:{"type":"number","min":"0"},domProps:{"value":(item.discount)},on:{"keyup":function($event){return _vm.$parent.calculateTotal()},"input":function($event){if($event.target.composing)return;_vm.$set(item, "discount", $event.target.value)}}})]):_vm._e(),_c('td',[_vm._v(_vm._s(item.total)+" "+_vm._s(_vm.$option.currency))]),_c('td',[(_vm.$parent.item.invoiceItems.length > 0)?_c('button',{staticClass:"btn btn-outline-danger",on:{"click":function($event){return _vm.$parent.deleteItem(index)}}},[_c('i',{staticClass:"far fa-trash"})]):_vm._e()])])})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }