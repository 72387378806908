var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('materials.Materials'),"description":_vm.$t('materials.FromHereYouCanControlTheMaterials'),"explanationPath":_vm.path + '/explaination'}}),_c('indexTable',{attrs:{"searchText":_vm.$t('materials.SearchForRawMaterial'),"emptyTableText":_vm.$t('materials.ThereAreNoMaterials'),"localData":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.admin || _vm.$user.role.products_add,"cloumns":[
                                  {column: 'name',    title: _vm.$t('materials.TheNameOfTheMaterial') ,type: 'mainLink' , sort: true },
                                  {column: 'section_id', title:_vm.$t('products.category')      ,type: 'link', to:'section', sort: true, link: false, icon: 'folders' },
                                  {column: 'cost',    title:_vm.$t('materials.Cost')    ,type: 'text'     , sort: true, icon: 'dollar-sign' },
                                  {column: 'options', title:_vm.$t('products.settings'), type: 'options', options: [
                                      {name: 'show'},
                                      {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.customers_edit},
                                      {name: 'printBarcode', title: _vm.$t('materials.BarcodePrint'), icon: 'barcode-alt'},
                                      {name: 'delete', role: _vm.$user.admin || _vm.$user.role.customers_delete},
                                  ]}
                                ],"deleteText":{
                                 attention: _vm.$t('allerts.Attention'),
                                 areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                                 close: _vm.$t('allerts.close'),
                                 confirm: _vm.$t('allerts.confirm'),
                                 loading: _vm.$t('allerts.loading'),
                                }}})],1)]),(_vm.importShow)?_c('importForm'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }