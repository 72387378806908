var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('purchaseDiscounts.purchaseDiscounts'),"description":_vm.$t('purchaseDiscounts.fromHereYouCanControlThepurchaseDiscounts')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('purchaseDiscounts.FindAPurchaseDiscount'),"emptyTableText":_vm.$t('purchaseDiscounts.thereAreNopurchaseDiscounts'),"buttonRole":_vm.$user.role.purchase_payments_add,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"statusSearch":true,"statusValues":[
          { title: _vm.$t('orders.situation'), value: ''},
          { title: _vm.$t('orders.all'), value: ''},
          { title: _vm.$t('invoices.draft'), value: 0 },
          { title: _vm.$t('invoices.approved'), value: 1 },
        ],"cloumns":[
          { column: 'code', title: _vm.$t('purchaseDiscounts.Discount'), type: 'mainLink', sort: true },
          { column: 'client_type', title: _vm.$t('purchaseDiscounts.supplierName'),type: 'clintLink',sort: true, },
          {
            column: 'date',
            title: _vm.$t('purchaseDiscounts.date'),
            type: 'text',
            sort: true,
          },
          {
            column: 'description',
            title: _vm.$t('purchaseDiscounts.thatsAbout'),
            type: 'text',
            sort: true,
          },
          {
            column: 'cost',
            title: _vm.$t('purchaseDiscounts.amount'),
            type: 'text',
            sort: true,
          },
          {column: 'status', title: _vm.$t('bills.situation') ,type: 'status' , sort: true ,  values: [
              {title: _vm.$t('invoices.draft'), value: 0, color: 'dark'},
              {title: _vm.$t('invoices.approved'), value: 1, color: 'success'},
            ]
          },
          {
            column: 'options',
            title: _vm.$t('purchaseDiscounts.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {name: 'printPurchaseDiscount',   role: true},
              {
                name: 'edit',
                role:
                  _vm.$user.admin ||
                  _vm.$user.role.purchase_payments_edit,
              },
              {name: 'file'},
              {
                name: 'delete',
                role:
                  _vm.$user.admin ||
                  _vm.$user.role.purchase_payments_delete,
              },
            ],
          },
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem:
            _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading'),
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }