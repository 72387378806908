var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('reports.accounts.reports'),"description":_vm.$t('reports.accounts.totalCurrentAccount')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('reports.accounts.reportOptions'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('reports.accounts.account'),"plaseholder":_vm.$t('reports.accounts.chooseAccount'),"show":'name',"refrance":'id',"value":_vm.params.account_id,"values":this.$database.accounts.filter((el) => el.stat == 1)},model:{value:(_vm.params.account_id),callback:function ($$v) {_vm.$set(_vm.params, "account_id", $$v)},expression:"params.account_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('reports.accounts.totalCurrentAccount'),"details":[
            {title: _vm.$t('reports.accounts.accuontName'), value : _vm.accountName }
          ]}}),_c('reportTable',{attrs:{"header":[
            { colspan: 6, value: _vm.$t('reports.accounts.openingBalance')},
            { colspan: 1, value: _vm.startBalance},
          ],"emptyTableText":_vm.$t('reports.accounts.thereNoDataTheMoment'),"emptyTableSubText":_vm.$t('reports.accounts.tryAddingSomeAndTryAgain'),"cloumns":[
            { column: 'code', title:_vm.$t('reports.accounts.code'), type: 'reportCode' },
            { column: 'date', title: _vm.$t('reports.accounts.date'), type: 'text' },
            { column: 'type', title: _vm.$t('reports.accounts.operation'), type: 'reportType' },
            { column: 'statement', title: _vm.$t('reports.overview.statement'), type: 'statement' },
            { column: 'debit', title: _vm.$t('reports.accounts.debtor'), type: 'textOrEmpty' },
            { column: 'creditor', title: _vm.$t('reports.accounts.creditor'), type: 'textOrEmpty' },
            { column: 'new_balance', title: _vm.$t('reports.accounts.balance'), type: 'text' },
          ],"footer":[
            { colspan: 4, value: _vm.$t('reports.accounts.finalBalance') },
            { colspan: 1, value: _vm.debit },
            { colspan: 1, value: _vm.creditor },
            { colspan: 1, value: _vm.endBalance },
          ],"tafqeet":_vm.tafqeet}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }