var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('reports.accounts.reports'),"description":_vm.$t('reports.overview.theRemainingBalanceCustomers')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('reports.accounts.reportOptions'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('reports.overview.customer'),"plaseholder":_vm.$t('reports.overview.chooseCustomer'),"show":'name',"refrance":'id',"value":_vm.params.customer_id,"values":_vm.customers},model:{value:(_vm.params.customer_id),callback:function ($$v) {_vm.$set(_vm.params, "customer_id", $$v)},expression:"params.customer_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('reports.overview.sectionCustomer'),"plaseholder":_vm.$t('reports.overview.chooseSection'),"show":'name',"refrance":'id',"value":_vm.params.sections_account_id,"values":_vm.$database.sectionsAccounts},model:{value:(_vm.params.sections_account_id),callback:function ($$v) {_vm.$set(_vm.params, "sections_account_id", $$v)},expression:"params.sections_account_id"}}),_c('selectInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('reports.overview.statue'),"plaseholder":_vm.$t('reports.overview.chooseStatue'),"values":[
                { name: _vm.$t('reports.overview.debtor'), value: 0 },
                { name: _vm.$t('reports.overview.creditor'), value: 1 },
                { name: _vm.$t('reports.overview.stopped'), value: 2 }
              ]},model:{value:(_vm.params.status),callback:function ($$v) {_vm.$set(_vm.params, "status", $$v)},expression:"params.status"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('reports.overview.theRemainingBalanceCustomers'),"details":[
            {title: _vm.$t('reports.overview.customerName'), value : _vm.customerName },
            {title: _vm.$t('reports.overview.sectionCustomer'), value : _vm.sectionCustomerName },
            {title: _vm.$t('reports.overview.statue'), value : _vm.statusName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('reports.accounts.thereNoDataTheMoment'),"emptyTableSubText":_vm.$t('reports.accounts.tryAddingSomeAndTryAgain'),"cloumns":[
            {column: 'name',  link: '/customers',   title:_vm.$t('reports.overview.customerName') ,type: 'reportMainLink' , sort: true },
            {column: 'balance',title: _vm.$t('reports.overview.balance'),type: 'text',sort: 'true'},
          ],"footer":[
            { colspan: 1, value: _vm.$t('reports.overview.total') },
            { colspan: 1, value: _vm.balance},
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }