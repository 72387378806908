var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('suppliers.suppliers'),"description":_vm.$t('suppliers.suppliersDescription'),"showExport":'true',"exportLink":_vm.path + '/export/excel',"showImport":'true',"explanationPath":_vm.path + '/explaination'}}),_c('indexTable',{attrs:{"searchText":_vm.$t('suppliers.searchForSupplier'),"emptyTableText":_vm.$t('suppliers.thereAreNoSuppliers'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.admin || _vm.$user.role.suppliers_add,"localData":true,"cloumns":[
          {
            column: 'name',
            title: _vm.$t('suppliers.supplierName'),
            type: 'mainLink',
            sort: true,
          },
          {
            column: 'mobile',
            title: _vm.$t('suppliers.phoneNumber'),
            type: 'text',
            sort: true,
            icon: 'phone'
          },
          {
            column: 'balance',
            title: _vm.$t('suppliers.balance'),
            type: 'text',
            sort: true,
            icon: 'usd-square'
          },
          {
            column: 'stat',
            title: _vm.$t('suppliers.active'),
            type: 'stat',
            sort: true,
            hideMobile: true
          },
          {
            column: 'options',
            title: _vm.$t('suppliers.actions'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$user.admin || _vm.$user.role.suppliers_edit,
              },
              {
                name: 'link',
                role:
                _vm.$site.purchasePayments_allow &&
                  (_vm.$user.admin ||
                    _vm.$user.role.purchase_payments_add),
                title: _vm.$t('suppliers.AddABatch'),
                icon: 'dollar-sign',
                link: '/purchasePayments/create?supplier_id=',
              },
              {
                name: 'link',
                role:
                _vm.$site.bills_allow &&
                  (_vm.$user.admin||_vm.$user.role.bills_add),
                title: _vm.$t('suppliers.AddAPurchaseBill'),
                icon: 'file-invoice-dollar',
                link: '/bills/create?supplier_id=',
              },
              {
                name: 'link',
                role:
                  _vm.$site.purchaseOrders_allow &&
                  (_vm.$user.admin ||
                    _vm.$user.role.purchase_orders_add),
                title: _vm.$t('suppliers.AddAPurchaseOrder'),
                icon: 'briefcase',
                link: '/purchaseorders/create?supplier_id=',
              },
              {
                name: 'delete',
                role:
                  _vm.$user.admin || _vm.$user.role.suppliers_delete,
              },
            ],
          },
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.AreYoureallySureYouDeletedTheResourceIfYouDeleteTheResourceAllInvoicesAndProcessesWillBedeleted'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading'),
        }}})],1)]),(_vm.importShow)?_c('importForm'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }