var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.$t('components.bankAccountsformSafesTransfers.TransfersFromTheBankAccount')))]),(_vm.$parent.item.stat == 1 && _vm.$site.salesPayments_allow && (_vm.$user.admin || _vm.$user.role.sales_payments_add))?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/safesTransfers/create?safe_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.bankAccountsformSafesTransfers.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$parent.$t('components.bankAccountsformSafesTransfers.searchForTransfer'),"emptyTableText":_vm.$parent.$t('components.bankAccountsformSafesTransfers.thereAreNoWithdrawalsOperations'),"filter":{safe_id: _vm.$route.params.id},"withoutCard":true,"emptyTableSubText":_vm.$parent.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
                     {column: 'code',    title: _vm.$parent.$t('components.bankAccountsformSafesTransfers.operation') ,type: 'mainLink' , sort: true },
                     {column: 'from_safe_id',    title: _vm.$parent.$t('components.bankAccountsformSafesTransfers.fromSafeTransefrs'), element:'fromSafe' ,type: 'link', to: 'safe', sort: true, link: true},
                     {column: 'to_safe_id',    title: _vm.$parent.$t('components.bankAccountsformSafesTransfers.toSafeTransefrs'), element:'toSafe' ,type: 'link', to: 'safe', sort: true, link: true},
                     {column: 'date',    title: _vm.$parent.$t('components.bankAccountsformSafesTransfers.transferDate') ,type: 'text' , sort: true },
                     {column: 'description',    title: _vm.$parent.$t('components.bankAccountsformSafesTransfers.thatsAbout') ,type: 'text' , sort: true },
                     {column: 'cost',    title: _vm.$parent.$t('components.bankAccountsformSafesTransfers.amount') ,type: 'text' , sort: true },
                     {column: 'options', title: _vm.$parent.$t('components.bankAccountsformSafesTransfers.settings'), type: 'options', options: [
                         {name: 'show'},
                         {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.safes_transfers_edit},
                         {name: 'delete', role: _vm.$user.admin || _vm.$user.role.safes_transfers_delete},
                     ]}
                   ],"deleteText":{
                    attention: _vm.$parent.$t('allerts.Attention'),
                    areYouReallySureToDeleteTheItem: _vm.$parent.$t('allerts.areYouReallySureToDeleteTheItem'),
                    close: _vm.$parent.$t('allerts.close'),
                    confirm: _vm.$parent.$t('allerts.confirm'),
                    loading: _vm.$parent.$t('allerts.loading'),
                   }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }