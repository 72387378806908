var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.$t('components.customersBills.bills')))]),(
          _vm.$parent.item.stat == 1 &&
          _vm.$site.bills_allow &&
          (_vm.$user.admin || _vm.$user.role.bills_add)
        )?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/bills/create?customer_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.customersBills.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$parent.$t('components.customersBills.searchForABill'),"withoutCard":true,"emptyTableText":_vm.$parent.$t('components.customersBills.thereAreNoBills'),"filter":{customer_id: _vm.$route.params.id},"emptyTableSubText":_vm.$parent.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"statusSearch":true,"statusValues":[
        { title: _vm.$parent.$t('components.customersBills.situation'), value: '' },
        { title: _vm.$parent.$t('components.customersBills.all'), value: '' },
        { title: _vm.$parent.$t('components.customersBills.open'), value: 0 },
        { title: _vm.$parent.$t('components.customersBills.driven'), value: 1 },
        { title: _vm.$parent.$t('components.customersBills.canceled'), value: 2 },
      ],"cloumns":[
        {
          column: 'code',
          title: _vm.$parent.$t('components.customersBills.bill'),
          type: 'mainLink',
          sort: true,
        },
        {
          column: 'date',
          title: _vm.$parent.$t('components.customersBills.theDateOfTheInvoice'),
          type: 'text',
          sort: true,
        },
        {
          column: 'client_type',
          title: _vm.$parent.$t('components.customersBills.supplierName'),
          type: 'clintLink',
          sort: true,
        },
        {
          column: 'total',
          title: _vm.$parent.$t('components.customersBills.cost'),
          type: 'text',
          sort: true,
        },
        {
          column: 'rest',
          title: _vm.$parent.$t('components.customersBills.balance'),
          type: 'text',
          sort: true,
        },
        {
          column: 'status',
          title: _vm.$parent.$t('components.customersBills.situation'),
          type: 'status',
          sort: true,
          values: [
            { title: _vm.$parent.$t('components.customersBills.open'), value: 0, color: 'dark' },
            {
              title: _vm.$parent.$t('components.customersBills.driven'),
              value: 1,
              color: 'success',
            },
            {
              title: _vm.$parent.$t('components.customersBills.canceled'),
              value: 2,
              color: 'danger',
            },
          ],
        },
        {
          column: 'options',
          title: _vm.$parent.$t('components.customersBills.settings'),
          type: 'options',
          options: [
            { name: 'show' },
            {
              name: 'edit',
              role:
                _vm.$user.admin ||
                _vm.$user.role.bills_edit,
            },
            { name: 'outLink', role: true },
            { name: 'printBill', role: true },
            { name: 'download', role: true },
            {
              name: 'delete',
              role:
                _vm.$user.admin ||
                _vm.$user.role.bills_delete,
            },
          ],
        },
      ],"deleteText":{
        attention: _vm.$parent.$t('allerts.Attention'),
        areYouReallySureToDeleteTheItem:
          _vm.$parent.$t('allerts.areYouReallySureToDeleteTheItem'),
        close: _vm.$parent.$t('allerts.close'),
        confirm: _vm.$parent.$t('allerts.confirm'),
        loading: _vm.$parent.$t('allerts.loading'),
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }