var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"image":_vm.item.image,"icon":'user',"title":_vm.item.name,"stat":_vm.item.stat,"statText":_vm.item.stat ? _vm.$t('customers.show.active') : _vm.$t('customers.show.inactive'),"subData":[
        {title: _vm.$t('customers.form.taxIdentificationNumber'), value: _vm.item.tax_number}
      ],"buttons":[
        {title: _vm.$t('customers.show.modulation'), icon: 'edit', link: _vm.path+'/'+_vm.$route.params.id+'/edit', role: _vm.$user.admin || _vm.$user.role.customers_edit},
        {title: _vm.$t('customers.show.ComprehensiveReport'), icon: 'file-chart-pie', link: '/customersDetailsAccounts?customer_id=' + _vm.$route.params.id, role: _vm.$user.admin || _vm.$user.role.reports_show},
        {title: _vm.$t('customers.show.AddABatch'), icon: 'dollar-sign', link: '/salesPayments/create?customer_id=' + _vm.$route.params.id, role:_vm.$site.salesPayments_allow && (_vm.$user.admin|| _vm.$user.role.sales_payments_add)},
        {title: _vm.$t('customers.show.AddABid'), icon: 'calculator', link: '/quotations/create?customer_id='+_vm.$route.params.id, role: _vm.$site.quotations_allow && (_vm.$user.admin|| _vm.$user.role.quotations_add)},
        {title: _vm.$t('customers.show.AddASalesBill'), icon: 'file-invoice-dollar', link: '/invoices/create?customer_id='+_vm.$route.params.id, role: _vm.$site.invoices_allow && (_vm.$user.admin || _vm.$user.role.invoices_add)},
        {title: _vm.$t('customers.show.AddArequest'), icon: 'briefcase', link: '/orders/create?customer_id='+_vm.$route.params.id, role: _vm.$site.orders_allow && (_vm.$user.admin || _vm.$user.role.orders_add)},
      ]}}),_c('div',{staticClass:"col-12"},[_c('showFile')],1),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('customers.show.Background'),"cloumns":{
                firstCloum: [
                  {icon: 'user', title: _vm.$t('customers.show.CustomerName'), value: _vm.item.name},
                  {icon: 'dollar-sign', title: _vm.$t('customers.show.Balance'), value: _vm.$moneyFormat(( _vm.$option.balance_type == 1 ? _vm.item.balance * -1 : _vm.item.balance )) }
                ],
                scondCloum: [
                  {icon: 'dollar-sign', title: _vm.$t('customers.show.OpeningBalance'), value: _vm.$moneyFormat(_vm.item.opening_balance)},
                  {icon: 'toggle-on', title: _vm.$t('customers.show.CustomerStatus'), value: _vm.item.stat == 1 ? _vm.$t('allerts.effective') : _vm.$t('allerts.Ineffective')},
                ]
              }}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('customers.show.BillingAddress'),"cloumns":{
                firstCloum: [
                  {icon: 'map-marker-alt', title: _vm.$t('customers.show.Address'), value: _vm.item.address1},
                  {icon: 'location-arrow', title: _vm.$t('customers.show.TheSide'), value: _vm.item.entity},
                  {icon: 'map-marked', title: _vm.$t('customers.show.ZipCode'), value: _vm.item.zip},
                ],
                scondCloum: [
                  {icon: 'map-marker-alt', title: _vm.$t('customers.show.TheSequel'), value: _vm.item.address2},
                  {icon: 'city', title: _vm.$t('customers.show.City'), value: _vm.item.city},
                  {icon: 'globe-americas', title: _vm.$t('customers.show.State'), value: _vm.item.country},
                ]
              }}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('customers.show.CommunicationInformation'),"cloumns":{
              firstCloum: [
                {icon: 'phone', title: _vm.$t('customers.show.MobileNumber'), value: _vm.item.mobile2? _vm.item.mobile + ' - ' + _vm.item.mobile2 : _vm.item.mobile},
              ],
              scondCloum: [
                {icon: 'at', title: _vm.$t('customers.show.Email'), value: _vm.item.email },
              ]
            }}}),(_vm.$site.invoices_allow && (_vm.$user.admin || _vm.$user.role.invoices_show))?_c('invoices',{staticClass:"col-12"}):_vm._e(),(_vm.$site.bills_allow && (_vm.$user.admin || _vm.$user.role.bills_show))?_c('bills',{staticClass:"col-12"}):_vm._e(),(_vm.$site.saleReturns_allow && (_vm.$user.admin || _vm.$user.role.sale_returns_show))?_c('salesReturns',{staticClass:"col-12"}):_vm._e(),(_vm.$site.purchaseReturns_allow && (_vm.$user.admin || _vm.$user.role.purchase_returns_show))?_c('purchaseReturns',{staticClass:"col-12"}):_vm._e(),(_vm.$site.salesPayments_allow && (_vm.$user.admin || _vm.$user.role.sales_payments_show))?_c('salesPayments',{staticClass:"col-12"}):_vm._e(),(_vm.$site.purchasePayments_allow && (_vm.$user.admin || _vm.$user.role.purchase_payments_show))?_c('purchasePayement',{staticClass:"col-12"}):_vm._e(),(_vm.$site.salesCheques_allow && (_vm.$user.admin || _vm.$user.role.sales_cheques_show))?_c('salesCheques',{staticClass:"col-12"}):_vm._e(),(_vm.$site.purchaseCheques_allow && (_vm.$user.admin || _vm.$user.role.purchase_cheques_show))?_c('purchaseCheques',{staticClass:"col-12"}):_vm._e(),(_vm.$site.salesDiscounts_allow && (_vm.$user.admin || _vm.$user.role.sales_discounts_show))?_c('salesDiscounts',{staticClass:"col-12"}):_vm._e(),(_vm.$site.purchaseDiscounts_allow && (_vm.$user.admin || _vm.$user.role.purchase_discounts_show))?_c('purchaseDiscounts',{staticClass:"col-12"}):_vm._e(),(_vm.$site.salesInstallments_allow && (_vm.$user.admin || _vm.$user.role.sales_installments_show))?_c('salesInstallments',{staticClass:"col-12"}):_vm._e(),(_vm.$site.purchaseInstallments_allow && (_vm.$user.admin || _vm.$user.role.purchase_installments_show))?_c('purchaseInstallments',{staticClass:"col-12"}):_vm._e(),_c('notes',{staticClass:"col-12 col-xl-7"}),_c('contacts',{staticClass:"col-12 col-lg-5"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }