var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('discounts.discountRates'),"description":_vm.$t('discounts.fromHereYouCanControlTheUnitsOfTheDiscountRates')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('discounts.SearchForADiscountRate'),"emptyTableText":_vm.$t('discounts.thereAreNoDiscountRates'),"localData":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":true,"cloumns":[
                 {column: 'name',    title:_vm.$t('discounts.nameTheDiscountRate') ,type: 'text' , sort: true },
                 {column: 'rate',    title:_vm.$t('discounts.discountRate') ,type: 'text' , sort: true, after: '%' },
                 {column: 'options', title:_vm.$t('discounts.settings'), type: 'options', options: [
                     {name: 'edit',   role: true},
                     {name: 'delete', role: true},
                 ]}
               ],"deleteText":{
                attention: _vm.$t('allerts.Attention'),
                areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                close: _vm.$t('allerts.close'),
                confirm: _vm.$t('allerts.confirm'),
                loading: _vm.$t('allerts.loading'),
               }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }