var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('shippingOperations.shippingOperations'),"description":_vm.$t('shippingOperations.FromHereYouCanControlShipments')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('shippingOperations.searchForOperation'),"emptyTableText":_vm.$t('shippingOperations.NoShipments'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.shippingOperations_add,"statusSearch":true,"statusValues":[
          { title: _vm.$t('shippingOperations.situation'), value: '' },
          { title: _vm.$t('shippingOperations.all'), value: '' },
          { title: _vm.$t('shippingOperations.draft'), value: 0 },
          { title: _vm.$t('shippingOperations.sent'), value: 1 },
          { title: _vm.$t('shippingOperations.seen'), value: 2 },
          { title: _vm.$t('shippingOperations.driven'), value: 3 },
          { title: _vm.$t('shippingOperations.canceled'), value: 4 },
        ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('shippingOperations.operationCode'),
            type: 'mainLink',
            sort: true,
          },
          {
            column: 'client_type',
            title: _vm.$t('shippingOperations.customerName'),
            type: 'clintLink',
            sort: false,
          },
          {
            column: 'date',
            title: _vm.$t('shippingOperations.date'),
            type: 'text',
            sort: true,
          },
          {
            column: 'invoice_id',
            title: _vm.$t('shippingOperations.invoiceCode'),
            type: 'linkInvoice',
            to:'invoice',
            sort: true,
            link: true
          },
          {
            column: 'tracking_number',
            title: _vm.$t('shippingOperations.trackingNumber'),
            type: 'text',
            sort: true,
          },
          {
            column: 'status',
            title: _vm.$t('shippingOperations.theStatus'),
            type: 'status',
            sort: true,
            values: [
              { title: _vm.$t('shippingOperations.draft'), value: 0, color: 'dark' },
              { title: _vm.$t('shippingOperations.sent'), value: 1, color: 'primary' },
              { title: _vm.$t('shippingOperations.seen'), value: 2, color: 'primary' },
              { title: _vm.$t('shippingOperations.driven'), value: 3, color: 'success' },
              { title: _vm.$t('shippingOperations.canceled'), value: 4, color: 'danger' },
            ],
          },
          {
            column: 'options',
            title: _vm.$t('shippingOperations.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              { name: 'edit', role: _vm.$user.admin || _vm.$user.role.shippingOperations_edit },
              { name: 'printInvoice', role: true },
              { name: 'delete', role: _vm.$user.admin || _vm.$user.role.shippingOperations_delete },
            ],
          },
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem:
            _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading'),
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }