var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade show"},[_c('div',{staticClass:"modal-dialog modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[(_vm.loading)?_c('div',{staticClass:"sm-stop-edit"}):_vm._e(),_c('div',{staticClass:"modal-card card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.$t('components.pointOfSalesPopUp.mergeTable')))]),_c('button',{staticClass:"close",attrs:{"type":"button"},on:{"click":function($event){return _vm.close()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-12"},[_vm._v(" "+_vm._s(_vm.$t('components.pointOfSalesPopUp.baseTable')))]),_c('div',{staticClass:"col-md-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fromTable),expression:"fromTable"}],staticClass:"form-control",attrs:{"disabled":""},domProps:{"value":(_vm.fromTable)},on:{"input":function($event){if($event.target.composing)return;_vm.fromTable=$event.target.value}}})])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-12"},[_vm._v(_vm._s(_vm.$t('components.pointOfSalesPopUp.subTable'))+" ")]),_c('div',{staticClass:"col-md-12"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.toTable),expression:"toTable"}],staticClass:"form-control",class:{ 'is-invalid' : _vm.errors.toTable},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.toTable=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v(_vm._s(_vm.$t('components.pointOfSalesPopUp.ChooseATable')))]),_vm._l((_vm.$tables[_vm.$parent.options.id]),function(table,index){return [(
                    index != 0 &&
                    index != _vm.$parent.moveTableId &&
                    _vm.$tables[_vm.$parent.options.id][index].item.invoiceItems.length == 0 &&
                    _vm.$tables[_vm.$parent.options.id][index].margeId == null &&
                    _vm.$tables[_vm.$parent.options.id][index].addedName.length == 0
                  )?_c('option',{key:index,domProps:{"value":index}},[_vm._v(_vm._s(table.name))]):_vm._e()]})],2)])])]),_c('div',{staticClass:"card-footer card-footer-boxed"},[_c('div',{staticClass:"row align-items-center justify-content-between"},[_c('div',{staticClass:"col-auto"}),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-outline-danger",on:{"click":function($event){return _vm.close()}}},[_c('i',{staticClass:"far fa-times"}),_vm._v(" "+_vm._s(_vm.$t('components.pointOfSalesPopUp.Close'))+" ")]),(!_vm.loading)?_c('button',{staticClass:"btn btn-dark",on:{"click":function($event){return _vm.margeTable()}}},[_c('i',{staticClass:"far fa-save"}),_vm._v(" "+_vm._s(_vm.$t('components.pointOfSalesPopUp.construction'))+" ")]):_vm._e(),(_vm.loading)?_c('button',{staticClass:"btn btn-dark"},[_vm._v(" "+_vm._s(_vm.$t('components.pointOfSalesPopUp.Download'))+" "),_c('span',{staticClass:"loading-s1 sm-j"},[_vm._v(".")]),_c('span',{staticClass:"loading-s2 sm-j"},[_vm._v(".")]),_c('span',{staticClass:"loading-s3 sm-j"},[_vm._v(".")])]):_vm._e()])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }