<template>
    <div class="container-fluid" v-if="!loading">
        <div class="row mt-4">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col ml-n2">
                                <h4 class="mb-1">
                                    <a>{{$t('invoices.form.invoice')}} <strong># {{item.code}}</strong></a>
                                </h4>
                            </div>
                            <div class="col-auto">
                                <router-link :to="path+'/'+item.id + '/edit'" class="btn btn-sm btn-primary" :title="$t('invoices.show.modulation')">
                                    <i class="fas fa-edit"></i>
                                </router-link>
                                <a @click="print()" class="btn btn-sm btn-primary" :title="$t('invoices.show.Printing')">
                                    <i class="fas fa-print"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-8">
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              {{ $t("منتجات الطلب") }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table
                    class="table"
                    :style="{ border: 'none !important' }"
                  >
                    <thead
                      :style="{
                        backgroundColor: 'white !important',
                        border: 'none !important',
                      }"
                    >
                      <tr>
                        <th
                          :style="{
                            backgroundColor: 'white !important',

                            border: 'none !important',
                            borderBottom: '1px solid black',
                          }"
                          class="px-0 border-top-0"
                        >
                          <span class="h6">{{
                            $t("orders.form.ProductItem")
                          }}</span>
                        </th>

                        <th
                          :style="{
                            backgroundColor: 'white !important',
                            border: 'none !important',
                            borderBottom: '1px solid black',
                          }"
                          class="px-0 border-top-0"
                        >
                          <span class="h6">{{ $t("orders.form.Quantity") }}</span>
                        </th>
                        <th
                          :style="{
                            backgroundColor: 'white !important',
                            border: 'none !important',
                            borderBottom: '1px solid black',
                          }"
                          class="px-0 border-top-0"
                        >
                          <span class="h6">{{ $t("orders.form.price") }}</span>
                        </th>
                        <th
                          :style="{
                            backgroundColor: 'white !important',
                            border: 'none !important',
                            borderBottom: '1px solid black',
                          }"
                          class="px-0 border-top-0"
                          v-if="item.discount != 0"
                        >
                          <span class="h6">{{ $t("orders.form.Discount") }}</span>
                        </th>
                        <th
                          :style="{
                            backgroundColor: 'white !important',
                            border: 'none !important',
                            borderBottom: '1px solid black',
                          }"
                          class="px-0 bg-transparent border-top-0"
                          v-if="item.tax != 0"
                        >
                          <span class="h6">{{ $t("orders.form.Tax") }}</span>
                        </th>
                        <th
                          :style="{
                            backgroundColor: 'white !important',
                            border: 'none !important',
                            borderBottom: '1px solid black',
                          }"
                          class="px-0 bg-transparent border-top-0"
                        >
                          <span class="h6">{{ $t("orders.form.Total") }}</span>
                        </th>
                        <th
                          :style="{
                            backgroundColor: 'white !important',
                            border: 'none !important',
                            borderBottom: '1px solid black',
                          }"
                          class="px-0 bg-transparent border-top-0"
                        >
                          <span class="h6">{{ $t("الحالة") }}</span>
                        </th>
                        <th
                          :style="{
                            backgroundColor: 'white !important',
                            border: 'none !important',
                            borderBottom: '1px solid black',
                          }"
                          class="px-0 bg-transparent border-top-0"
                        >
                          <span class="h6">{{ $t("الإعدادات") }}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(qItem, indexs) in item.quotationItems"
                        :key="qItem.id"
                        :style="{ border: 'none !important' }"
                      >
                        <td
                          class="px-0"
                          :style="{
                            border: 'none !important',
                            borderBottom: '1px solid black',
                          }"
                        >
                          {{ qItem.product_name }}
                        </td>
                        <!-- <td class="px-0">
                              {{ qItem.description }}
                          </td> -->
                        <td
                          class="px-0"
                          :style="{
                            border: 'none !important',
                            borderBottom: '1px solid black',
                          }"
                        >
                          {{ qItem.quantity.toFixed(2) }}
                          {{ qItem.unit ? qItem.unit.unit : "" }}
                        </td>
                        <td
                          class="px-0"
                          :style="{
                            border: 'none !important',
                            borderBottom: '1px solid black',
                          }"
                        >
                          {{ $moneyFormat(qItem.price) }}
                        </td>
                        <td class="px-0" v-if="item.discount != 0">
                          -{{ $moneyFormat(qItem.discount) }}
                        </td>
                        <td class="px-0" v-if="item.tax != 0">
                          {{ $moneyFormat(qItem.average) }}
                        </td>
                        <td
                          class="px-0"
                          :style="{
                            border: 'none !important',
                            borderBottom: '1px solid black',
                          }"
                        >
                          {{ $moneyFormat(qItem.total) }}
                        </td>
                        <td
                          class="px-0"
                          :style="{
                            border: 'none !important',
                            borderBottom: '1px solid black',
                          }"
                        >
                          <div
                            :class="'badge badge-' + value.color"
                            v-for="value in statusData"
                            v-if="value.value == qItem.status"
                          >
                            {{ value.title }}
                          </div>
                        </td>
                        <td
                          class="px-0"
                          :style="{
                            border: 'none !important',
                            borderBottom: '1px solid black',
                          }"
                        >
                          <i class="fas fa-edit" style="cursor: pointer; margin-left: 10px;;" @click="openStatus(indexs)"></i>
                          <i class="fas fa-print" style="cursor: pointer;" @click="printItem(indexs)"></i>
                        </td>
                      </tr>

                      <tr v-if="item.delivery > 0">
                        <td colspan="2" class="px-0 border-top border-top-2">
                          <strong>{{ $t("orders.show.ShippingFee") }}</strong>
                        </td>
                        <td colspan="7" class="px-0 border-top border-top-2">
                          <span class="h3">
                            {{ $moneyFormat(item.delivery) }}
                          </span>
                        </td>
                      </tr>
                      <tr v-if="item.edit > 0">
                        <td colspan="2" class="px-0 border-top border-top-2">
                          <strong>{{
                            item.edit_text
                              ? item.edit_text
                              : $t("orders.show.Modification")
                          }}</strong>
                        </td>
                        <td colspan="7" class="px-0 border-top border-top-2">
                          <span class="h3">
                            {{ $moneyFormat(item.edit) }}
                          </span>
                        </td>
                      </tr>
                      <tr v-if="item.discount != 0">
                        <td colspan="2" class="px-0 border-top border-top-2">
                          <strong>{{ $t("orders.form.totalDiscount") }}</strong>
                        </td>
                        <td colspan="7" class="px-0 border-top border-top-2">
                          <span class="h3">
                            - {{ $moneyFormat(item.discount) }}
                          </span>
                        </td>
                      </tr>
                      <tr v-if="item.tax != 0">
                        <td colspan="2" class="px-0 border-top border-top-2">
                          <strong>{{ $t("orders.form.totalTaxes") }}</strong>
                        </td>
                        <td colspan="7" class="px-0 border-top border-top-2">
                          <span class="h3">
                            {{ $moneyFormat(item.tax) }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="w-100" v-if="item.terms != null">
                  <hr class="my-3" />
                  <h4 class="text-uppercase">
                    {{ $t("orders.form.termsOfOffer") }}
                  </h4>
                  <div class="text-muted mb-0" v-html="item.notes"></div>
                </div>
                <div
                  class="w-100 inv-footer"
                  v-if="$option.invoice_footer != null"
                >
                  <hr class="my-3" />
                  <div class="text-muted mb-0" v-html="item.terms"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            <div class="col-12 col-md-4">
                <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              {{ $t("orders.form.invoice") }} : #{{ item.code }}
            </h4>
          </div>
          <div class="card-body">
            <p>
              {{ $t("orders.show.createdIn") }} {{ item.created }}, (<small
                >{{ $t("orders.show.LastUpdate") }} {{ item.updated }}</small
              >)
            </p>
            <ul class="list-unstyled">
              <li v-if="item.client_type == 0">
                <strong>{{ $t("orders.show.Client") }}</strong> :
                <router-link :to="'/customers/' + item.customer.id">
                  {{ item.customer.name }}</router-link
                >
              </li>
              <li v-if="item.client_type == 1">
                <strong>{{ $t("orders.show.Client") }}</strong> :
                <router-link :to="'/suppliers/' + item.supplier.id">
                  {{ item.supplier.name }}</router-link
                >
              </li>
              <li>
                <strong>{{ $t("orders.show.Administrator") }}</strong> :
                {{ item.user.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              {{ $t("orders.form.Total") }}:
            </h4>
          </div>
          <div class="card-body">
            <h6 class="text-uppercase text-muted">
              {{ $t("orders.form.Subtotal") }}:
            </h6>
            <p class="mb-2">
              {{ $moneyFormat(item.subtotal + item.discount) }}
            </p>
            <h6 class="text-uppercase text-muted">
              {{ $t("orders.form.Total") }}:
            </h6>
            <p class="mb-2">
              {{ $moneyFormat(item.total) }}
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">{{ $t("orders.form.date") }}:</h4>
          </div>
          <div class="card-body">
            <h6 class="text-uppercase text-muted">
              {{ $t("orders.form.date") }}
            </h6>
            <p class="mb-2">
              {{ $dateFormat(item.date) }}
            </p>
            <h6 class="text-uppercase text-muted">
              {{ $t("orders.form.ExpiryDate") }}
            </h6>
            <p class="mb-2">
              {{ $dateFormat(item.expiry_date) }}
            </p>
          </div>
        </div>

                <showFile />
                
                <div class="card" v-if="storesHistories.length != 0">
                    <div class="card-header">
                        <h4 class="card-header-title">
                            <i class="far fa-store-alt"></i> {{$t('invoices.show.WarehouseOperations')}}
                        </h4>
                    </div>
                    <table class="table table-sm main-show">
                        <thead>
                            <tr>
                                <th scope="col">{{$t('invoices.show.Lots')}}</th>
                                <th scope="col">{{$t('invoices.show.theProduct')}}</th>
                                <th scope="col">{{$t('invoices.show.TheStore')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="storeHistory in storesHistories" :key="storeHistory.id">
                                <td>
                                    {{storeHistory.type == 0 ? '+' : '-'}}
                                    {{storeHistory.quantity}}
                                </td>
                                <td>
                                    <router-link :to="'/products/' + storeHistory.product.id" class="btn btn-light">
                                        {{storeHistory.product.name}}
                                    </router-link>
                                </td>
                                <td>
                                    <router-link :to="'/stores/' + storeHistory.store.id" class="btn btn-light">
                                        {{storeHistory.store.name}}
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <editStat v-if="edit_id != null" />
    </div>
</template>
<script>
    import axios from 'axios';
    import editStat from "./components/editStat.vue";
    import QrcodeVue from 'qrcode.vue'
    import showFile from '@/elements/show/showFile.vue';
    export default {
        data() {
            return {
                path: '/invoices',
                logo: localStorage.getItem('logo'),
                item: {},
                salesPayments: {},
                storesHistories: {},
                salesPaymentForm: false,
                addId: this.$route.params.id,
                amount: 0,
                type: 'show',
                loading: true,
                itemsCount: 0,

                edit_id: null,
                statusData: [
                    { title: this.$t('orders.NewOrder'), value: 1, color: 'dark'},
                    { title: this.$t('orders.BeingProcessed'), value: 2, color: 'info' },
                    { title: this.$t('orders.readyForShipping'), value: 3, color: 'info' },
                    { title: this.$t('orders.UnderShipping'), value: 4, color: 'info' },
                    { title: this.$t('orders.shipped'), value: 5, color: 'success' },
                    { title: this.$t('orders.complete'), value: 6, color: 'success' },
                    { title: this.$t('orders.returnd'), value: 7, color: 'danger'},
                    { title: this.$t('orders.canceled'), value: 8, color: 'danger' },
                ],
                loading: true,

                printInvoice: false,
                site: localStorage.getItem('site')
            }
        },
        mounted() {
            this.getitem();
        },
        methods: {
            getitem() {
                axios.get(this.$linkGnirator(this.path + '/' + this.$route.params.id))
                    .then(response => {
                        this.item = response.data;
                        this.salesPayments = this.item.sales_payments;
                        this.storesHistories = this.item.stores_histories;
                        this.amount = this.item.rest;
                        this.item.quotationItems.forEach(item => {
                            this.itemsCount += (item.quantity - 0);
                        });
                        if (this.$option.tax_invoice) {

                          var sellerNameBuf = this.getTlvForValue("1", this.$option.name);

                          var vatRegistrationNameBuf = this.getTlvForValue("2", this.$option.tax_number);

                          var timeBuf = this.getTlvForValue("3" , this.item.created_at);

                          var totalInvoiceBuf = this.getTlvForValue("4", this.item.total.toFixed(2).toString());

                          var taxAmountBuf = this.getTlvForValue("5" , this.item.tax.toFixed(2).toString())
                          var tagBufsArray = [sellerNameBuf, vatRegistrationNameBuf, timeBuf, totalInvoiceBuf, taxAmountBuf  ];

                          var qrCodeBuf = Buffer.concat(tagBufsArray);

                          var qrCodeB64 = qrCodeBuf.toString('base64');

                          this.item.qrCode = qrCodeB64;
                        }
                        this.loading = false;
                    });
            },

            getTlvForValue (tagNum, tagValue) {

                var tagBuf = Buffer.from([tagNum], 'utf8');

                var tagvalueBuf = Buffer.from(tagValue, 'utf8');

                var tagValueLenBuf = Buffer.from([tagvalueBuf.length], 'utf8')

                var bufsArray = [tagBuf,tagValueLenBuf,tagvalueBuf]

                return Buffer.concat(bufsArray);
            },

            print() {
                this.$parent.printedInvoice = this.item;
            },
            openStatus (id){
            this.edit_id = id
            },
            printItem(id) {
                this.$parent.printedOrderItem = this.item;
                this.$parent.printedOrderItemId = id
                },
            changeStatus(status) {
        axios
        .post(this.$linkGnirator('/invoiceItem/status/' + this.item.quotationItems[this.edit_id].id + '/' + status  ), {
            _method: 'POST'
        })
        .then(response => {
            this.getitem()
            this.edit_id = null
            return response
        })
    },
        },
        components: {
          QrcodeVue,
          showFile,
          editStat
        },
    };

</script>
